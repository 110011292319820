import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import ValidationService from "../../../services/ValidationAccountService";
import apiClient from "../../../auth/apiClient";
import { useSelector } from "react-redux";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import GroupedDropdown from "../Grouped";
import clsx from "clsx";
import { FindNearestFacilityFromEntity } from "../../../state/slices/entities";
import _ from "lodash";

const validationService = new ValidationService(apiClient);

const formatValidationOffers = (groupedOffers) => {
  const flat = [];
  if (!groupedOffers) return flat;
  groupedOffers.forEach((group, groupIndex) => {
    if (!group.offers) return;
    group.offers.forEach((offer, offerIndex) => {
      offer.account = group.account;
      offer.label = offer.name;
      offer.render = (
        <Typography
          className="val-offer-dropdown-value"
          data-id={`${groupIndex}-${offerIndex}`}
          data-account-value={group.account}
          data-value={offer.name}
        >
          {offer.name}
        </Typography>
      );
      flat.push(offer);
    });
  });
  return flat;
};

/**
 *
 * @param {Array} props.validationOffers When provided, the contractHolderIDs property will be ignored and the component will be driven off of the given validationOffers
 * @returns
 */
export default function MultiValidationOfferDropdown({
  className,
  contractHolderIDs,
  onChange,
  label,
  validationOffers,
  deviceID,
}) {
  const [cachedValidationOffers, setCachedValidationOffers] = useState();
  const [filteredValidationOffers, setFilteredValidationOffers] = useState();
  const [noResults, setNoResults] = useState(false);

  const facilityID = useSelector((state) =>
    deviceID
      ? FindNearestFacilityFromEntity(
          state.entities?.EntityList ?? [],
          deviceID
        )?.entityid ?? ""
      : state.entities?.ContextID
  );

  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    if (validationOffers) {
      setCachedValidationOffers(validationOffers);
      setFilteredValidationOffers(formatValidationOffers(validationOffers));
      setNoResults(validationOffers.length === 0);
    }
  }, [facilityID, JSON.stringify(validationOffers)]);

  useEffect(() => {
    if (!validationOffers) getValidationOffers();
  }, [facilityID, JSON.stringify(contractHolderIDs)]);

  const getValidationOffers = useCallback(async () => {
    try {
      const response = await validationService.getValidationOffersForGivenValidationAccounts(
        facilityID,
        contractHolderIDs
      );
      setCachedValidationOffers(response.data);
      setFilteredValidationOffers(formatValidationOffers(response.data));
      setNoResults(response.data.length === 0);
    } catch (err) {
      console.error(err);
      if (err.response?.status === 400) {
        enqueueSnackbar("Unable to retrieve validation offers", {
          variant: "warning",
          tag: "FailedToRetrieveValidationOffers",
        });
      } else
        enqueueSnackbar("Failed to retrieve validation offers", {
          variant: "error",
          tag: "FailedToRetrieveValidationOffers",
        });
        setNoResults(true);
    }
  }, [facilityID, JSON.stringify(contractHolderIDs)]);

  const handleInputChange = (searchTerm) => {
    if (!cachedValidationOffers) return;
    if (!searchTerm || searchTerm === "") {
      setFilteredValidationOffers(
        formatValidationOffers(cachedValidationOffers)
      );
      setNoResults(false);
      return;
    }

    // just filter in memory for now
    const cachedCopy = _.cloneDeep(cachedValidationOffers);
    const filteredOffers = cachedCopy.filter(
      (x) => x.offers.some((c) => c.name?.toLowerCase().includes(searchTerm?.toLowerCase()))
    ).map((y) => { 
      y.offers =  y.offers.filter((c) => c.name?.toLowerCase().includes(searchTerm?.toLowerCase()));
      return y;
    });

    setFilteredValidationOffers(formatValidationOffers(filteredOffers));
    setNoResults(filteredOffers.length === 0);
  };

  return (
    <div className={clsx("validation-offer-dropdown", className)}>
    <GroupedDropdown
      name="validation-offer"
      onInputChange={handleInputChange}
      groupBy="account"
      options={filteredValidationOffers}
      itemIdentifier="label"
      renderField="render"
      onSelect={onChange}
      label={label ?? ""}
    />
      {noResults && (
    <span>No authorized validations available for the facility</span>  
      )}
    </div>
  );
}

MultiValidationOfferDropdown.defaultProps = {
  label: "Search",
};

MultiValidationOfferDropdown.propTypes = {
  className: PropTypes.string,
  contractHolderIDs: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  label: PropTypes.string,
  validationOffers: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string.isRequired,
      offers: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  deviceID: PropTypes.string,
};
