import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import Container from "@material-ui/core/Container";
import Copyright from "../../components/Footer/Copyright";
import SigninForm from "../../components/SigninForm";
import useAuthContext from "../../hooks/useAuthContext";
import { Redirect, useHistory } from "react-router-dom";
import NewPassword from "../../components/NewPassword";
import { AuthStatus } from "../../reducers/auth/authReducer";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import VerifyCodeForm from "../../components/VerifyCodeForm";
import { useLocation, Route, Switch } from "react-router-dom";
import { Grid } from "@material-ui/core";
import useUserContext from "../../hooks/useUserContext";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useDispatch } from 'react-redux';
import { setUsername, setFirstname, setLastname } from "../../state/slices/user";

export default function LoginPage() {
  const classes = useStyles();
  const {
    authReducer,
    setUserSession,
    authService,
    markAsUserHasLoggedIn
  } = useAuthContext();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [authState] = authReducer;
  const [resetPassword, setResetPassword] = React.useState(false);
  const [invalidCredentials, setInvalidCredentials] = React.useState(false);
  const [verifyCode, setVerifyCode] = React.useState(false);
  const [newPasswordErrors, setNewPasswordErrors] = React.useState({
    newPassword: null
  });
  const [verifyCodeErrors, setVerifyCodeErrors] = React.useState({
    code: null,
    newPassword: null,
    resend: null
  });
  const [forgotPasswordErrors, setForgotPasswordErrors] = React.useState({
    email: null
  });
  const [forgotEmail, setForgotEmail] = React.useState(null);
  const [userEmail, setUserEmail] = React.useState("");
  const [isResending, setIsResending] = React.useState(false);
  const [passwordReset, setPasswordReset] = React.useState(false);
  const history = useHistory();
  const { updateInfo } = useUserContext();
  const location = useLocation();
  const dispatch = useDispatch();

  const invalidMessage = (
    <Typography
      component="h2"
      name="invalid message"
      className={classes.invalid}
    >
      Invalid Log In
    </Typography>
  );

  const passwordResetMessage = (
    <Typography
      component="h2"
      name="passwordReset message"
      className={classes.success}
    >
      Password Reset
    </Typography>
  );

  const onLoginSubmit = async values => {
    const { email, password } = values;
    setUserEmail(email);
    setInvalidCredentials(false);
    setPasswordReset(false);
    await authService
      .signInUser(email, password)
      .then(session => {
        setUserSession(session);
        markAsUserHasLoggedIn();
      })
      .catch(err => {
        if (err.type === "newPasswordRequired") {
          setResetPassword(true);
        }
        if (err.type === "failure") {
          setInvalidCredentials(true);
        }
      });
  };

  const onNewPasswordSubmit = async values => {
    const { newPassword, firstName, lastName, ...rest } = values;
    await authService
      .completeNewPasswordChallenge(newPassword, rest)
      .then(session => {
        setUserSession(session).then(() => {
          setNewPasswordErrors({ newPassword: null });
          setResetPassword(false);
        });
        updateUsersName(firstName, lastName);
      })
      .catch(({ code, message }) => {
        console.error(message);
        if (code === "InvalidPasswordException") {
          setNewPasswordErrors({
            newPassword: { message: sanitizePasswordMessage(message) }
          });
        } else if (code === "InvalidParameterException") {
          setNewPasswordErrors({
            newPassword: { message: message }
          });
        }
      });
  };

  const updateUsersName = async (firstName, lastName) => {
    try {
      const updateRes = await updateInfo({
        email: userEmail,
        firstName: firstName,
        lastName: lastName
      });
      if (updateRes?.status === 200) {
        dispatch(setUsername(`${firstName} ${lastName}`));
        dispatch(setFirstname(`${firstName}`));
        dispatch(setLastname(`${lastName}`));
        enqueueSnackbar(`Successfully updated user's name`, {
          variant: "success"
        });
      } else {
        enqueueSnackbar(`Failed to update user's name`, {
          variant: "error",
          tag: "FailedToUpdateUserName"
        });
      }
    } catch (err) {
      enqueueSnackbar(`Failed to update user's name`, {
        variant: "error",
        tag: "FailedToUpdateUserName"
      });
    }
  };

  const sanitizePasswordMessage = message => {
    return message?.replace("Password does not conform to policy: ", "");
  };

  const onForgotPasswordSubmit = async values => {
    const { email } = values;
    await authService
      .forgotPassword(email)
      .then(() => {
        setForgotEmail(email);
        setVerifyCode(true);
        setForgotPasswordErrors({ email: null });
      })
      .catch(({ code, message }) => {
        setForgotPasswordErrors({ email: { message: message } });
      });
  };

  const resendVerificationCode = async email => {
    setIsResending(true);
    await authService
      .forgotPassword(email)
      .then(() =>
        setVerifyCodeErrors({
          code: null,
          newPassword: null,
          resend: null
        })
      )
      .catch(({ code, message }) => {
        setVerifyCodeErrors({
          ...verifyCodeErrors,
          resend: { message: message }
        });
      })
      .finally(setIsResending(false));
  };

  const onVerifyCodeSubmit = async values => {
    const { code, newPassword } = values;
    await authService
      .confirmPassword(code, newPassword)
      .then(result => {
        setPasswordReset(true);
        cancelForgotPassword();
        setVerifyCodeErrors({
          code: null,
          newPassword: null,
          resend: null
        });
      })
      .catch(({ code, message }) => {
        if (code === "InvalidPasswordException") {
          setVerifyCodeErrors({
            ...verifyCodeErrors,
            newPassword: { message: sanitizePasswordMessage(message) }
          });
        } else if (code === "InvalidParameterException") {
          setVerifyCodeErrors({
            newPassword: { message: message }
          });
        } else if (code === "CodeMismatchException") {
          setVerifyCodeErrors({
            code: { message: message }
          });
        }
      });
  };

  const cancelForgotPassword = () => {
    setVerifyCode(false);
    setForgotEmail(null);
    history.push("/login");
  };

  return (
    <Container component="main" maxWidth="xs">
      {authState.authStatus === AuthStatus.AUTHENTICATED &&
        location.pathname != "/login/reset" && <Redirect to={`/home`} />}
      <div className={classes.paper}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <img
            style={{ marginTop: 10, marginBottom: 10 }}
            width="55%"
            src="/images/logos/a1-logo-stacked-dark.svg"
          />
        </Grid>
        <Switch>
          <Route exact path="/login">
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {invalidCredentials && invalidMessage}
            {passwordReset && passwordResetMessage}
            {resetPassword ? (
              <NewPassword
                onSubmit={onNewPasswordSubmit}
                cognitoErrors={newPasswordErrors}
              />
            ) : (
              <SigninForm onSubmit={onLoginSubmit} />
            )}
          </Route>
          <Route exact path="/login/reset">
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            {verifyCode ? (
              <VerifyCodeForm
                onCancel={cancelForgotPassword}
                isResending={isResending}
                resendCode={resendVerificationCode}
                email={forgotEmail}
                onSubmit={onVerifyCodeSubmit}
                cognitoErrors={verifyCodeErrors}
              />
            ) : (
              <ForgotPasswordForm
                onCancel={cancelForgotPassword}
                onSubmit={onForgotPasswordSubmit}
                cognitoErrors={forgotPasswordErrors}
              />
            )}
          </Route>
        </Switch>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
