import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Tooltip, withStyles, Button, useTheme, useMediaQuery, Divider, Typography, Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AddIcon } from "@material-ui/data-grid";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { onFiltersChanged } from "../../../reducers/contracts/contractReducer";
import ContractService from "../../../services/ContractService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import Dropdown from "../../Dropdowns/SingleSelect";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import AccessHolderFilters from "../../../components/Filters/AccessHolderFilters";
import useContractContext from "../../../hooks/useContractContext";
import AccessGroupService from "../../../services/AccessGroupService";
import { useSelector } from "react-redux";
import AccessHolderSearchButtons from "./Search/accessHolderSearchButtons";
import AccessHolderPageableList from "./AccessHolderPageableList";
import useCancellationToken from "../../../hooks/useCancellationToken";
import Title from "../../Title";
import { useStyles } from "./styles";
import SearchService from "../../../services/SearchService";
import Pagination from "@material-ui/lab/Pagination";

const contractService = new ContractService(apiClient);
const groupService = new AccessGroupService(apiClient);
const searchService = new SearchService(apiClient);

function getRowsByHeight(height) {
  return Math.round(height / 130);
}

const AccessHolderList = ({ onSelect, onAddClick }) => {
  const theme = useTheme();
  const classes = useStyles();

  const { facilityID } = useCurrentFacility();
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const [accessHolders, setAccessHolders] = useState({
    totalCount: 0,
    collection: [],
    display: []
  });
  const [accessHolder, setAccessHolder] = useState();
  const [groups, setGroups] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const accessHolderAdd = hasPermissions(["accessholders.add"]);
  const accessHolderEdit = hasPermissions(["accessholders.edit"]);
  const [isOpen, setIsOpen] = useState(false);
  const [values, dispatch] = useContractContext();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  
  const [loading, setSearchLoading] = useState(false);

  const [activeSearch, setActiveSearch] = useState();
  const smallWindow = useMediaQuery(theme.breakpoints.down("sm"));

  const availableStatuses = [
    { value: "", name: "" },
    { value: "in", name: "In" },
    { value: "out", name: "Out" },
    { value: "neutral", name: "Neutral" }
  ];

  const availableModes = [
    { value: "", name: "" },
    { value: "normal", name: "Normal" },
    { value: "locked", name: "Locked" },
    { value: "override", name: "Override" },
    { value: "override ap", name: "Override AP" },
    { value: "inactive", name: "Inactive" }
  ];

  const AccessGroupTooltip = withStyles(theme => ({
    tooltip: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.black,
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
      zIndex: 1,
      padding: 20,
      fontSize: "15px",
      maxWidth: 250,
      whiteSpace: "pre-line"
    },
    arrow: {
      color: theme.palette.common.black,
      fontSize: 16,
      width: 17
    }
  }))(Tooltip);

  useEffect(() => {
    fetchAccessGroups();
  }, [scopeAwareFacilityID]);

  const fetchAccessGroups = useCallback(async () => {
    let response;
    try {
      response = await groupService.GetAccessGroups(scopeAwareFacilityID, 100, 0, "");
    } catch {
      enqueueSnackbar("Failed to retrieve access groups", {
        variant: "error",
        tag: "fetchAccessGroupsError"
      });
      return;
    }

    let tmpGroups =
      response?.data?.collection?.map(group => {
        return { name: group.name, value: group.accessGroupID };
      }) ?? [];
    setGroups(tmpGroups);
  }, [scopeAwareFacilityID]);

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleStatusSelect = async (status, holder) => {
    if (!holder) return;
    let previousStatus = holder.status;
    let tmpAccessHolder = holder;
    tmpAccessHolder.status = status;
    if (!(await updateAccessHolder(tmpAccessHolder)))
      setAccessHolder(prev => ({ ...prev, status: previousStatus }));
  };

  const handleModeSelect = async (mode, holder) => {
    if (!holder) return;
    let previousMode = holder.mode;
    let tmpAccessHolder = holder;
    tmpAccessHolder.mode = mode;
    if (!(await updateAccessHolder(tmpAccessHolder)))
      setAccessHolder(prev => ({ ...prev, mode: previousMode }));
  };

  const updateAccessHolder = async accessHolder => {
    try {
      setAccessHolder(await contractService.updateAccessHolder(accessHolder));
      enqueueSnackbar("Successfully updated access holder", {
        variant: "success",
        tag: "accessHolderUpdated"
      });
      return true;
    } catch {
      enqueueSnackbar("Failed to update access holder", {
        variant: "error",
        tag: "updateAccessHolderError"
      });
      return false;
    }
  };

  const displayAccessGroupsToolTip = groups => {
    let display = "No Access Groups";
    let list = "";
    if (groups?.length > 0) {
      groups.forEach(group => {
        list = list + group.name + "\n";
        display = list;
      });
    }
    return display;
  };

  const displayAccessGroups = groups => {
    let display = "Multiple Access Groups";
    if (groups?.length == 0) {
      display = "No Access Groups";
    }
    if (groups?.length == 1) {
      display = groups[0].name;
    }
    return display;
  };

  const filtersApplied = () => {
    return (values.credentialType || values.status || values.mode || values.group);
  }

  const getNoResultMessage = () => {
    if (filtersApplied()) {
      return "No mathcing results with filters applied"
    } else {
      return "No results found"
    }
  }

  const formatAccessHolderData = rawAccessHolders => {
    const formatted = rawAccessHolders.map(accessHolder => {
      return {
        id: accessHolder.accessHolderID,
        displayContent: (
          <Grid container className={clsx("grid-container")} spacing={2}>
            <Grid item container className={clsx("grid-item-container")} direction="column" data-testid={`grid-item-${accessHolder.accessHolderID}`}>
              <Grid item className={clsx("grid-item")}>
                <Box className={clsx("access-holder-name")} fontWeight="bold" fontSize="large">
                  <Grid container className={clsx("grid-tooltip-container")}>
                      <Grid item className={clsx("grid-item-holder-name")} xs={4} data-testid="grid-item-holder-name">
                        <Grid container>
                          <Grid item xs={12}>
                            {`${accessHolder.contactInfo.firstName} ${accessHolder.contactInfo.lastName}`}
                          </Grid>
                          <AccessGroupTooltip className={clsx("group-tooltip", classes.groupToolTip)} arrow title={displayAccessGroupsToolTip(accessHolder.accessGroups)} data-testid="group-tooltip"> 
                            <Grid>
                              <Typography variant="body2" color="primary">
                                { displayAccessGroups(accessHolder.accessGroups) } 
                              </Typography>
                            </Grid>
                          </AccessGroupTooltip>
                        </Grid>
                      </Grid>

                    <Grid item className={clsx("grid-item-status-dropdown", classes.statusDropDownGrid)} xs={4}>
                      <Dropdown
                        className={clsx("status-dropdown", classes.statusDropDown)}
                        data-testid="dropdown-select-status"
                        name="Status"
                        options={availableStatuses}
                        onSelect={e => handleStatusSelect(e, accessHolder)}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        value={accessHolder?.status?.toLowerCase() ?? ""}
                        disabled={!accessHolderEdit}
                        InputProps={{
                          readOnly: Boolean(!accessHolderEdit),
                          "aria-readonly": Boolean(!accessHolderEdit),
                          disabled: Boolean(!accessHolderEdit)
                        }}
                      />
                    </Grid>
                    <Grid item className={clsx("grid-item-mode-dropdown", classes.modeDropDownGrid)}  xs={4}>
                      <Dropdown
                        className={clsx("mode-dropdown", classes.modeDropDown)}
                        data-testid="dropdown-select-mode"
                        name="Mode"
                        options={availableModes}
                        onSelect={e => handleModeSelect(e, accessHolder)}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        value={accessHolder?.mode?.toLowerCase() ?? ""}
                        disabled={!accessHolderEdit}
                        InputProps={{
                          readOnly: Boolean(!accessHolderEdit),
                          "aria-readonly": Boolean(!accessHolderEdit),
                          disabled: Boolean(!accessHolderEdit)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      };
    });

    setAccessHolders(prev => ({ ...prev, display: formatted }));
  };

  const handleAddAccessHolder = () => {
    onAddClick();
  };

  const handleAccessHolderClick = accessHolderID => {
    if (isOpen == true) {
      return;
    }
    onSelect(accessHolderID);
  };

  const handlePageChange = (e, page) => {
    dispatch({
      type: onFiltersChanged,
      payload: { accessHolderPage: page }
    });
  };

  const getSearch = async ({ cancelToken }) => {
		setSearchLoading(true);
    const page = activeSearch.page ?? 1;
		const offset = ((page - 1) * itemLimit);

    const response =
      await searchService.searchAccessHolders(
        scopeAwareFacilityID,
        activeSearch.searchTerm,
        activeSearch.searchCategory,
        values.credentialType,
        values.status,
        values.mode,
        values.group,
        itemLimit, 
        offset,
        cancelToken
      );

		setSearchLoading(false);
    setAccessHolders(response?.data);
    if (response?.data?.totalCount > 0) formatAccessHolderData(response?.data?.collection);
	}

	const { execute: executeSearchQuery } = useCancellationToken({
		func: getSearch,
		errHandleFunc: () => {
			setSearchLoading(false);
			enqueueSnackbar("Failed to load search", {
				variant: "error",
				tag: "FailedToLoadSearch",
			});
		},
	});


	const onSearch = (searchState) => {
		setActiveSearch(searchState);
	}

  useEffect(()=> {
    if (activeSearch) {
      executeSearchQuery();
    }
  }, [facilityID, 
      activeSearch,
      accessHolder,
      itemLimit, 
  ])

  useEffect(() => {
    if(activeSearch) {
      setActiveSearch({ ...activeSearch, page: values.accessHolderPage });
    }
  }, [values.accessHolderPage,
      values.credentialType,
      values.status,
      values.mode,
      values.group,
  ])

  return (
    <>
    <Box className={clsx("pageable-entity", "access-holder-pageable-entity")}>
      <Grid container  spacing={2} className={clsx(classes.header)}>
        <Grid item xs={9} sm={6}>
            <Title>Access Holders</Title>
        </Grid>
        {accessHolderAdd && (
          <Grid item xs={3} sm={6} className={clsx("pageable-add-button-grid", classes.addBtn)}>
            {smallWindow ? (
              <Button
                className={clsx("pageable-add-button", classes.mobileAdd)}
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={handleAddAccessHolder}
              />
            ) : (
              <Button
                className={clsx("pageable-add-button")}
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={handleAddAccessHolder}
              >
                Add Access Holder
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} md={9}>
          <AccessHolderSearchButtons onSearch={onSearch} loading={loading} />
        </Grid>
        <Grid item xs={12} md={3}>
        {Math.ceil(accessHolders.totalCount / itemLimit) > 1 && (
          <Grid container className={clsx(classes.contentRoot)}>
            <Grid item xs={12} className={clsx(classes.paginationContainer)}>
              <Pagination
                  className={clsx("pagination-container")}
                  page={values.accessHolderPage ?? 1}
                  onChange={handlePageChange}
                  count={Math.ceil(accessHolders.totalCount / itemLimit)}
                  color="primary"
                  shape="rounded"
              />
            </Grid>
          </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
    <Divider  className={clsx(classes.divider)} />
    <Grid container spacing={2} className={clsx(classes.contentRoot)}>
      <Grid item xs={12} className={clsx(classes.filterContainer)}>
        {activeSearch && (filtersApplied() || loading || (!loading && accessHolders.totalCount > 0))  && (
          <AccessHolderFilters groups={groups} />
        )}
      </Grid>
    </Grid>
    
     {/* loading indicator and no-results display */}

     <Grid container className={clsx(["information-container"])}>
      <Grid item xs={12}>
        {activeSearch && (
          loading ? (
            <div className={clsx(["loading",classes.skeletonWrapper])}>
              <Skeleton height={"100px"}  className={classes.skeleton} animation="wave" />
              <Skeleton height={"100px"} className={classes.skeleton} animation="wave" />
              <Skeleton height={"100px"} className={classes.skeleton} animation="wave" />
            </div>
          ):(
            <>
            <Divider className={classes.resultsDivider}></Divider>
            {accessHolders?.collection?.length === 0 && (
              <>
              <div className={clsx(["animate__animated","animate__flipInX","results","not-found",classes.noResults])}>
                <span className={clsx(["no-results-text"])}>{getNoResultMessage()}</span>
              </div>
              <Grid item className={clsx(["init",classes.initialScreen])}>        
              <Typography>
                Enter Search Criteria to Locate Access Holders
              </Typography>
              {accessHolderAdd && (
                <Typography>
                  Or Add a&nbsp;
                  <Link color="primary" underline="always" onClick={() => handleAddAccessHolder()} style={{ cursor: "pointer" }} > 
                    New Access Holder
                  </Link>
                </Typography>
              )}
            </Grid>
            </>
            )}
            </>
          )
        )}
        {!activeSearch && (
          <>  
          <Grid item className={clsx(["init",classes.initialScreen])}>        
            <Typography>
              Enter Search Criteria to Locate Access Holders
            </Typography>
            {accessHolderAdd && (
            <Typography>
              Or Add a&nbsp;
              <Link color="primary" underline="always" onClick={() => handleAddAccessHolder()} style={{ cursor: "pointer" }} > 
                New Access Holder
              </Link>
            </Typography>
            )}
          </Grid>
          </>
        )}
      </Grid>
    </Grid>
    {activeSearch && (
      <AccessHolderPageableList
        onItemClicked={handleAccessHolderClick}
        items={accessHolders.display}
        isLoading={loading}
      />
    )}
    </>
  );
};

AccessHolderList.defaultProps = {
  onSelect: () => {},
  onAddClick: () => {}
};

AccessHolderList.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func
};

export default AccessHolderList;
