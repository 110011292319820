import React, { useState, useEffect, useCallback } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import moment from "moment-timezone";
import { Box, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import CredentialService from "../../../../services/CredentialService";
import apiClient from "../../../../auth/apiClient";
import * as _ from "lodash";
import ColoredLine from "../../../ColoredLine";
import { useSelector } from "react-redux";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

const credentialService = new CredentialService(apiClient);

const formDescriptionText = (foundValOffer, appliedVal) => {
  const activityDate = moment
    .utc(appliedVal?.activationDate)
    .local()
    .format("LLLL");
  const formattedAmount = foundValOffer?.amount?.toFixed(2);
  switch (foundValOffer.type) {
    case "Amount":
      return `$${-1 * formattedAmount} off applied on ${activityDate}`;
    case "Flat Fee":
      return `Flat Fee of $${formattedAmount} applied on ${activityDate}`;
    case "Percentage":
      return `${-1 * formattedAmount}% off applied on ${activityDate}`;
    case "Rate Switch":
      return `Rate was changed to ${foundValOffer.name} on ${activityDate}`;
    case "Adjust Entry Time":
      return `Entry Time moved forward ${-1 * formattedAmount} minutes`;
    case "Adjust Exit Time":
      return `Exit Time moved backward ${-1 * formattedAmount} minutes`;
  }
};

const InternalFormat = "YYYY-MM-DDTHH:mm:ssZ";

/**
 * Iterates through the validations applied to the ticket,
 * finds the corresponding validation offer that we already have,
 * formats the validation applied entry with quick summary
 * @param ticketValidations
 */
const formatValidationsApplied = (ticketValidations, offers) => {
  let formattedValidations = [];
  ticketValidations.map((appliedVal, index) => {
    const foundValOffer = _.find(offers.map((group) => group.offers).flat(), {
      id: appliedVal.validationOfferID,
    });
    if (foundValOffer) {
      formattedValidations.push({
        id: appliedVal.validationsInventoryID,
        content: (
          <div key={`applied-validation-${index}`}>
            {formDescriptionText(foundValOffer, appliedVal)}
          </div>
        ),
      });
    }
  });
  return formattedValidations;
};

const TicketAutoValidationRow = ({
  ticket,
  validationOffers,
  autoApplyValidationOffer,
  onSendApplyValidationRunningChange,
  automaticallyApplyValidationEnabled
}) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const validateTicketsPermission = hasPermissions(["validatetickets"]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const currentUserID = useSelector((state) => state.user?.UserID);
  const [validations, setValidations] = useState(ticket?.validations ?? []);
  const { facilityID } = useCurrentFacility();
  const handleSendApplyValidationRunningChange = (value) => onSendApplyValidationRunningChange(value);

  useEffect(() => {
    setValidations(ticket?.validations ?? []);
    if (automaticallyApplyValidationEnabled && validationOffers && autoApplyValidationOffer && ticket?.validations && validationOffers.length > 0) {
      const foundOffer = validationOffers[0].offers.find((offer) => offer.id === autoApplyValidationOffer);
      if (foundOffer) {
        sendApplyValidation(foundOffer);
        return;
      }
    }
    enqueueSnackbar("No validations available for this ticket", {
      variant: "error",
      tag: "FailedToApplyValidation",
    });
  }, [automaticallyApplyValidationEnabled, validationOffers, autoApplyValidationOffer, ticket])

  const sendApplyValidation = useCallback(async (selectedValOffer) => {

    if (!ticket || !selectedValOffer) return;

    if (automaticallyApplyValidationEnabled && ticket.validations.some((validation) => validation.validationOfferID === selectedValOffer.id)) {
      enqueueSnackbar("Only 1 validation limit per ticket", {
        variant: "error",
        tag: "TicketValidationAccountExceeded",
      });
      return;
    }

    const activationDate = moment(Date.now()).format(InternalFormat);

    const validationReq = {
      type: selectedValOffer.type,
      kind: "online",
      format: "QR Code",
      barcodeValue: ticket.ticketId,
      status: "Used",
      contractID: ticket.contractId ?? null,
      validationBundleID: selectedValOffer.validationBundleID,
      currentUses: 1,
      maxUses: 1,
      maxUsesPerTransaction: 1,
      isValid: true,
      amount: selectedValOffer.amount,
      entityID: ticket.entityID ?? null,
      rateBlobID: selectedValOffer.rateBlobID ?? null,
      creationDate: moment(Date.now()).format(InternalFormat),
      activationDate,
      validationOfferID: selectedValOffer.id,
      isPersistant: true,
      userID: currentUserID,
    };
    let validationResponse;
    try {

      handleSendApplyValidationRunningChange(true);
      validationResponse = await credentialService.CreateValidation(
        validationReq
      );
      if (validationResponse.status != 200) {
        enqueueSnackbar("Failed to apply validation", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
        return;
      }
    } catch (err) {
      enqueueSnackbar("Failed to apply validation", {
        variant: "error",
        tag: "FailedToApplyValidation",
      });
      return;
    }

    try {
      const claimedResponse = await credentialService.tryApplyValidationWithinLimit(
        validationResponse.data.id,
        ticket.parkingTransactionID,
        facilityID
      );
      if (claimedResponse.status != 200) {
        enqueueSnackbar("Failed to apply validation", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
      } else {

        setValidations((prev) => [
          {
            validationOfferID: selectedValOffer.id,
            validationsInventoryID: validationResponse.data.id,
            activationDate,
            amount: selectedValOffer.amount,
            type: selectedValOffer.type,
            name: selectedValOffer.name,
          },
          ...prev,
        ]);

        enqueueSnackbar("Successfully applied validation", {
          variant: "success",
        });        
      }
    } catch (err) {
      if(err.response?.data=== "Limit Exceed"){
        enqueueSnackbar("The allowed validation limit has been reached for the ticket", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
      }else{
        enqueueSnackbar("Failed to apply validation", {
          variant: "error",
          tag: "FailedToApplyValidation",
        });
      }
    }
  }, [automaticallyApplyValidationEnabled, ticket]);

  return (
    <div
      key={ticket?.ticketID}
      className={clsx(
        `ticket-validation-row-${ticket?.ticketID}`,
        classes.TicketAutoValidationRow
      )}
    >
      <div
        className={clsx(`ticket-info-${ticket?.ticketID}`, classes.ticketInfo)}
      >
        <Tooltip title="Ticket ID" aria-label="ticket-id">
          <Typography
            className={classes.ticketIdText}
            color="primary"
            variant="h5"
          >
            {ticket?.ticketID?.slice(ticket.ticketID?.length - 12) ?? ""}
          </Typography>
        </Tooltip>
        <Tooltip title="Last Activity" aria-label="last-activity">
          <Box width="fit-content">
            {moment
              .utc(ticket?.issuedDate ?? ticket?.activityDate)
              .local()
              .format("LLLL")}
          </Box>
        </Tooltip>
      </div>
      <div
        className={clsx(
          `validate-section-${ticket?.ticketID}`,
          classes.validateContainer
        )}
      >
        {automaticallyApplyValidationEnabled && validateTicketsPermission && (
          <>
            <ColoredLine />
            <div className={classes.validationsApplied}>
              {validations?.length > 0 &&
                formatValidationsApplied(validations, validationOffers).map(
                  (val, index) => {
                    if (automaticallyApplyValidationEnabled && autoApplyValidationOffer === val.id) {
                      enqueueSnackbar("Only 1 validation limit per ticket", {
                        variant: "error",
                        tag: "TicketValidationAccountExceeded",
                      });
                    }

                    return (
                      <React.Fragment key={val.id}>
                        <div className={classes.validationDescriptionContainer}>
                          <div
                            className={clsx(
                              `applied-validation-${ticket?.ticketID}-${val.id}`,
                              classes.appliedValidation
                            )}
                          >
                            {val.content}
                          </div>
                        </div>
                        <ColoredLine />
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TicketAutoValidationRow;

TicketAutoValidationRow.defaultProps = {
  validationOffers: [],
  autoApplyValidationOffer: PropTypes.string
};

TicketAutoValidationRow.propTypes = {
  validationOffers: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string.isRequired,
      offers: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ),
};