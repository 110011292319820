import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import useDebounce from "../../../hooks/useDebounce";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";

export default function GroupedDropdown({
  className,
  label,
  options,
  groupBy,
  itemIdentifier,
  onInputChange,
  delay,
  loading,
  onSelect,
  renderField,
}) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState();
  const [focused, setFocused] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, delay);

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      onInputChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleChange = (e) => {
    if (!e) return;
    setSearchTerm(e.target.value);
  };

  const handleFocus = () => {
    setFocused(true);
    setSearchTerm();
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleSelect = (e, value) => {
    onSelect(value);
  };

  return (
    <Autocomplete
      data-testid="grouped-dropdown-autocomplete"
      id="grouped-dropdown"
      className={clsx(className, classes.dropdown)}
      options={options}
      groupBy={(option) => option[groupBy]}
      getOptionLabel={(option) => option[itemIdentifier]}
      onInputChange={handleChange}
      open={focused && options?.length > 0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      blurOnSelect
      onChange={handleSelect}
      autoHighlight
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" className="group-dropdown" />
      )}
      filterOptions={(option) => option}
      renderOption={(option) => option[renderField]}
      popupIcon={
        loading ? (
          <CircularProgress className={classes.loadingIcon} size={30} />
        ) : options?.length > 0 ? (
          <ArrowDropDownIcon />
        ) : (
          <></>
        )
      }
    />
  );
}

GroupedDropdown.defaultProps = {
  options: [],
  onInputChange: () => {},
  delay: 0,
  loading: false,
  onSelect: () => {},
};

GroupedDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.object,
  groupBy: PropTypes.string.isRequired,
  itemIdentifier: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  onInputChange: PropTypes.func,
  delay: PropTypes.number,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  renderField: PropTypes.string.isRequired,
};
