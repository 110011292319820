import {
  faTachometerAlt,
  faCog,
  faHeadset,
  faSitemap,
  faChartLine,
  faIdCard,
  faSearch,
  faUserEdit,
  faSync,
  faUserFriends,
  faCogs,
  faMoneyCheckEditAlt,
  faUsersCog,
  faDollarSign,
  faStoreAlt,
  faFileImport,
  faDesktop,
  faGarageOpen,
  faHome,
  faParkingCircle,
  faUserLock,
  faBullhorn,
  faCashRegister,
  faFileMagnifyingGlass,
  faTurtle,
  faPretzel,
} from "@fortawesome/pro-duotone-svg-icons";
import { faScrubber } from "@fortawesome/pro-light-svg-icons";
import { faMoneyBillTransfer } from "@fortawesome/pro-solid-svg-icons";
import { ENTITY_TYPE } from "../../../constants/index";

export const linksArray = {
  home: {
    icon: faHome,
    text: "Home",
    path: "/home",
    id: "home-nav",
  },
  dashboard: {
    icon: faTachometerAlt,
    text: "Dashboard",
    path: "/dashboard",
    id: "dashboard-nav",
    showIfContainsAnyPermission: [
      "dashboard.activity.view",
      "dashboard.alerts.view",
      "dashboard.revenue.view",
      "dashboard.occupancy.view",
    ],
  },
  monitoring: {
    icon: faDesktop,
    text: "Monitoring",
    parent: true,
    showIfContainsAnyPermission: [
      "devicecontrol.view",
      "inventory.view",
      "transactions.view",
      "callcenter.view",
      "overview.view",
      "alerts.view",
    ],
    id: "monitoring-nav",
    children: {
      deviceControl: {
        icon: faCog,
        text: "Device Control",
        path: "/devicecontrol",
        permissions: ["devicecontrol.view"],
        id: "device-control-nav",
      },
      inventory: {
        icon: faGarageOpen,
        text: "Inventory",
        path: "/inventory",
        permissions: ["inventory.view"],
        id: "credential-inventory-nav",
      },
      auditTool: {
        icon: faFileMagnifyingGlass,
        text: "Audit Tool",
        path: "/audittool",
        permissions: ["audittool.view"],
        id: "audit-tool-nav",
      },
      callCenter: {
        icon: faHeadset,
        text: "Call Center",
        path: "/call",
        permissions: ["callcenter.view"],
        id: "call-center-nav",
      },
      overview: {
        icon: faSitemap,
        text: "Overview",
        path: "/tree",
        permissions: ["overview.view"],
        id: "overview-nav",
      },
    },
  },
  validate: {
    icon: faMoneyCheckEditAlt,
    text: "Validate",
    path: "/validate",
    showIfContainsAnyPermission: [
      "createprintedvalidations",
      "validatetickets",
    ],
    id: "validate-nav",
  },
  reports: {
    icon: faChartLine,
    text: "Reports",
    parent: true,
    showIfContainsAnyPermission: [
      "AccessholderActivity",
      "AccessholderStatus",
      "AccessholderDetail",
      "AlarmsReport",
      "CallCenter",
      "CashierShift",
      "CashInventory",
      "CreditCardTransactionData",
      "CountActivityDiff",
      "CountActivityNonReset",
      "CountStatistics",
      "DispenserTransaction",
      "Duration",
      "EntryExit",
      "FPAStatement",
      "GraceLagTransaction",
      "HotelAccessHolders",
      "HotelCharges",
      "OverUsage",
      "ParkingFee",
      "PrepaidPass",
      "PrepaidPassStatus",
      "RateDetails",
      "RevenueTransaction",
      "SharedAccountActivity",
      "SummaryTotals",
      "TicketmasterActivity",
      "TicketStatus",
      "TimeParameter",
      "TransactionDetails",
      "UserActions",
      "ValetActivity",
      "ValidationStatement",
      "Vehicles"
    ],
    id: "reports-nav",
    children: {
      accessHolderActivity: {
        icon: faScrubber,
        text: "Access Holder Activity",
        path: "/reports/AccessholderActivity",
        id: "accessholder-activity-nav",
        permissions: ["AccessholderActivity"],
      },
      accessHolderDetail: {
        icon: faScrubber,
        text: "Access Holder Detail",
        path: "/reports/AccessholderDetail",
        id: "accessholder-detail-nav",
        permissions: ["AccessholderDetail"],
      },
      accessHolderStatus: {
        icon: faScrubber,
        text: "Access Holder Status",
        path: "/reports/AccessholderStatus",
        id: "accessholder-status-nav",
        permissions: ["AccessholderStatus"],
      },
      alarmsReport: {
        icon: faScrubber,
        text: "Alarms and Alerts",
        path: "/reports/AlarmsReport",
        id: "alarms-alerts-nav",
        permissions: ["AlarmsReport"],
      },
      callCenter: {
        icon: faScrubber,
        text: "Call Center",
        path: "/reports/CallCenter",
        id: "call-center-nav",
        permissions: ["CallCenter"],
      },
      cashierShift: {
        icon: faScrubber,
        text: "Cashier Shift",
        path: "/reports/CashierShift",
        id: "cashier-shift-nav",
        permissions: ["CashierShift"],
        flag: "Cashiered Device"
      },
      cashInventory: {
        icon: faScrubber,
        text: "Cash Inventory",
        path: "/reports/CashInventory",
        id: "cash-inventory-nav",
        permissions: ["CashInventory"],
      },
      countActivityDiff: {
        icon: faScrubber,
        text: "Count Activity Differentials",
        path: "/reports/CountActivityDiff",
        id: "count-actv-diff-nav",
        permissions: ["CountActivityDiff"],
      },
      countActivityNonReset: {
        icon: faScrubber,
        text: "Count Activity Non-Resets",
        path: "/reports/CountActivityNonReset",
        id: "count-actv-nonreset-nav",
        permissions: ["CountActivityNonReset"],
      },
      countStatistics: {
        icon: faScrubber,
        text: "Count Statistics",
        path: "/reports/CountStatistics",
        id: "count-statistics-nav",
        permissions: ["CountStatistics"],
      },
      creditCardTransactionData: {
        icon: faScrubber,
        text: "Credit Card Transaction",
        path: "/reports/CreditCardTransactionData",
        id: "credit-card-transaction-data-nav",
        permissions: ["CreditCardTransactionData"],
      },
      dispenserTransaction: {
        icon: faScrubber,
        text: "Dispenser Transaction",
        path: "/reports/DispenserTransaction",
        id: "dispenser-transaction-nav",
        permissions: ["DispenserTransaction"],
      },
      duration: {
        icon: faScrubber,
        text: "Duration of Stay",
        path: "/reports/Duration",
        id: "duration-nav",
        permissions: ["Duration"],
      },
      entryExit: {
        icon: faScrubber,
        text: "Entry Exit",
        path: "/reports/EntryExit",
        id: "entry-exit-nav",
        permissions: ["EntryExit"],
      },
      FPAStatement: {
        icon: faScrubber,
        text: "Flexible Parking Accounts",
        path: "/reports/FPAStatement",
        id: "fpa-statement-nav",
        permissions: ["FPAStatement"],
        flag: "Flexible Parking Account"
      },
      graceLagTransaction: {
        icon: faScrubber,
        text: "Grace and Lag Transaction",
        path: "/reports/GraceLagTransaction",
        id: "grace-lag-transaction-nav",
        permissions: ["GraceLagTransaction"],
      },
      hotelAccessHolders: {
        icon: faScrubber,
        text: "Hotel Self-Park Guest",
        path: "/reports/HotelAccessHolders",
        id: "hotel-accessholders-nav",
        permissions: ["HotelAccessHolders"],
        flag: "Hotel Integrations",
      },
      hotelCharges: {
        icon: faScrubber,
        text: "Hotel Self-Park Charges",
        path: "/reports/HotelCharges",
        id: "hotel-charges-nav",
        permissions: ["HotelCharges"],
        flag: "Hotel Integrations",
      },
      overUsage: {
        icon: faScrubber,
        text: "Over Usage",
        path: "/reports/OverUsage",
        id: "over-usage-nav",
        permissions: ["OverUsage"],
      },
      parkingFee: {
        icon: faScrubber,
        text: "Parking Fee",
        path: "/reports/ParkingFee",
        id: "parking-fee-nav",
        permissions: ["ParkingFee"],
      },
      prepaidPass: {
        icon: faScrubber,
        text: "Prepaid Pass",
        path: "/reports/PrepaidPass",
        id: "prepaid-pass-nav",
        permissions: ["PrepaidPass"],
      },
      prepaidPassStatus: {
        icon: faScrubber,
        text: "Prepaid Pass Status",
        path: "/reports/PrepaidPassStatus",
        id: "prepaid-pass-status-nav",
        permissions: ["PrepaidPassStatus"],
      },
      rateDetails: {
        icon: faScrubber,
        text: "Rate Details",
        path: "/reports/RateDetails",
        id: "rate-details-nav",
        permissions: ["RateDetails"],
      },
      revenueTransaction: {
        icon: faScrubber,
        text: "Revenue Transaction",
        path: "/reports/RevenueTransaction",
        id: "revenue-transaction-nav",
        permissions: ["RevenueTransaction"],
      },
      sharedAccountActivity: {
        icon: faScrubber,
        text: "Shared Account Activity",
        path: "/reports/SharedAccountActivity",
        id: "shared-accounts-nav",
        permissions: ["SharedAccountActivity"],
        flag: "Shared Accounts"
      },
      summaryTotals: {
        icon: faScrubber,
        text: "Summary Totals",
        path: "/reports/SummaryTotals",
        id: "summary-totals-nav",
        permissions: ["SummaryTotals"],
      },
      ticketmasterActivity: {
        icon: faScrubber,
        text: "Ticketmaster Activity",
        path: "/reports/TicketmasterActivity",
        id: "ticketmaster-activity-nav",
        permissions: ["TicketmasterActivity"],
        flag: "Ticketmaster"
      },
      ticketStatus: {
        icon: faScrubber,
        text: "Ticket Status",
        path: "/reports/TicketStatus",
        id: "ticket-status-nav",
        permissions: ["TicketStatus"],
      },
      timeParameter: {
        icon: faScrubber,
        text: "Time Parameter",
        path: "/reports/TimeParameter",
        id: "time-parameter-nav",
        permissions: ["TimeParameter"],
      },
      transactionDetails: {
        icon: faScrubber,
        text: "Transaction Details",
        path: "/reports/TransactionDetails",
        id: "transaction-detail-nav",
        permissions: ["TransactionDetails"],
      },
      userActions: {
        icon: faScrubber,
        text: "User Actions",
        path: "/reports/UserActions",
        id: "user-actions-nav",
        permissions: ["UserActions"],
      },
      valetActivity: {
        icon: faScrubber,
        text: "Valet Activity",
        path: "/reports/ValetActivity",
        id: "valet-activity-nav",
        permissions: ["ValetActivity"],
        flag: "CVPS Dual Pairing"
      },
      validationStatement: {
        icon: faScrubber,
        text: "Validation Statement",
        path: "/reports/ValidationStatement",
        id: "validation-statement-nav",
        permissions: ["ValidationStatement"],
      },
      vehicles: {
        icon: faScrubber,
        text: "Vehicles Report",
        path: "/reports/Vehicles",
        id: "vehicles-report-nav",
        permissions: ["Vehicles"],
      },
    },
  },
  access: {
    icon: faIdCard,
    text: "Access",
    parent: true,
    showIfContainsAnyPermission: [
      "search.view",
      "accessholders.view",
      "statussync.view",
      "accessgroups.view",
      "groupcontracts.view",
    ],
    id: "access-nav",
    children: {
      search: {
        icon: faSearch,
        text: "Search",
        path: "/inventory?type=ticket",
        permissions: ["search.view"],
        id: "ticket-inventory-nav",
      },
      accessHolders: {
        icon: faUserEdit,
        text: "Access Holders",
        path: "/contracts/accessholders",
        permissions: ["accessholders.view"],
        id: "access-holders-nav",
      },
      statusSync: {
        icon: faSync,
        text: "Status Sync",
        path: "/status",
        permissions: ["statussync.view"],
        id: "status-sync-nav",
      },
      accessGroups: {
        icon: faUserFriends,
        text: "Access Groups",
        path: "/groups",
        permissions: ["accessgroups.view"],
        id: "access-groups-nav",
      },
      groupContracts: {
        icon: faUserFriends,
        text: "Group Contracts",
        path: "/groupcontracts",
        permissions: ["groupcontracts.view"],
        id: "group-contracts-nav",
        flag: "Flexible Parking Account"
      },
    },
  },
  settings: {
    icon: faCogs,
    text: "Settings",
    id: "settings-nav",
    parent: true,
    showIfContainsAnyPermission: [
      "system.view",
      "users.view",
      "rates.view",
      "validationaccounts.view",
      "validationaccountsettings",
      "notifications.view",
      "import.view",
      "general.view",
      "usergroups.view",
      "routines.view",
      "roamingcreditcardterminalsetup.view",
      "cashiersessions.view",
      "ScheduledReports",
    ],
    children: {
      // system: {
      //   icon: faTools,
      //   text: "System",
      //   path: "/system",
      //   permissions: ["system.view"],
      //   id: "system-nav",
      // },

      userGroups: {
        icon: faUsersCog,
        text: "Users/Groups",
        path: "/users",
        showIfContainsAnyPermission: ["users.view", "usergroups.view"],
        id: "users-nav",
      },
      rates: {
        icon: faDollarSign,
        text: "Rates/Taxes",
        path: "/rates",
        permissions: ["rates.view"],
        id: "rates-nav",
      },
      payonentry: {
        icon: faMoneyBillTransfer,
        text: "Pay On Entry",
        path: "/payonentry",
        permissions: ["payonentry.view"],
        id: "pay-on-entry-nav",
        flag: "Pay On Entry"
      },
      cashier: {
        icon: faCashRegister,
        text: "Cashier",
        path: "/cashier",
        showIfContainsAnyPermission: [
          "cashiersessions.view",
          "roamingcreditcardterminalsetup.view",
        ],
        id: "cashier-nav",
        flag: "Cashiered Device"
      },
      validationAccounts: {
        icon: faStoreAlt,
        text: "Validation Accounts",
        path: "/validationaccounts",
        showIfContainsAnyPermission: [
          "validationaccounts.view",
          "validationaccountsettings",
        ],
        id: "validation-accounts-nav",
      },
      routineService: {
        icon: faBullhorn,
        text: "Routine Service",
        path: "/routineservice",
        permissions: ["routines.view"],
        flag: "routinesMainMenu",
        id: "routine-service-nav",
      },
      general: {
        icon: faCog,
        text: "General",
        path: "/general",
        permissions: ["general.view"],
        id: "general-nav",
      },
      accessHolderImport: {
        icon: faFileImport,
        text: "Access Holder Import",
        path: "/import",
        permissions: ["import.view"],
        id: "access-holder-import-nav",
      },
      scheduledreports: {
        icon: faChartLine,
        text: "Scheduled Reports",
        path: "/scheduledreports",
        permissions: ["ScheduledReports"],
        id: "scheduled-reports-nav",
        flag: "Scheduled Reports"
      },
    },
  },
  shiftSession: {
    icon: faCashRegister,
    text: "Shift Session",
    path: "/shiftsession",
    showIfContainsAnyPermission: [
      "cashiermodes.payonentrycashier",
      "cashiermodes.roamingcashier",
    ],
    id: "shift-session-nav",
    flag: "Cashiered Device",
    entityType: ENTITY_TYPE.Facility,
  },
  shiftSessionValet: {
    icon: faCashRegister,
    text: "Shift Session",
    path: "/shiftsession",
    showIfContainsAnyPermission: [
      "cashiermodes.payonentrycashier",
      "cashiermodes.roamingcashier",
    ],
    id: "shift-session-nav",
    flag: "Cashiered Device.Valet Cashiered Device",
    entityType: ENTITY_TYPE.ValetArea,
  },

  administration: {
    icon: faUserLock,
    text: "Administration",
    id: "nav-admin",
    amiAdminOnly: true,
    children: {
      adminDashboard: {
        icon: faParkingCircle,
        text: "Dashboard",
        path: "/admin/dashboard",
        id: "nav-admin-dashboard",
        amiAdminOnly: true,
      },
      playground: {
        icon: faTurtle,
        text: "Playground",
        path: "/admin/playground",
        id: "admin-playground",
        flag: "Playground",
        amiAdminOnly: true,        
      },         
      deviceVersions: {
        icon: faParkingCircle,
        text: "Device Versions",
        path: "/admin/versions",
        id: "nav-admin-deviceversions",
        amiAdminOnly: true,
      },
      integratorSetup: {
        icon: faParkingCircle,
        text: "Integrator Setup",
        path: "/admin/integrators",
        id: "nav-third-party-integration",
        amiAdminOnly: true,
      },
      users: {
        icon: faParkingCircle,
        text: "Users",
        path: "/admin/users",
        id: "nav-admin-users",
        amiAdminOnly: true,
      },
      flagTest: {
        icon: faPretzel,
        text: "FlagTest",
        path: "/admin/flagtest",
        id: "admin-flagtest",
        flag: "Administration.Testing.FlagTest",
        amiAdminOnly: true,
      },
      deviceScripts: {
        icon: faParkingCircle,
        text: "Deployable Scripts",
        path: "/admin/deployable-scripts",
        id: "nav-admin-deployable-scripts",
        amiAdminOnly: true,
      },
      facilityActivity: {
        icon: faParkingCircle,
        text: "Facility Activity",
        path: "/admin/facilityactivity",
        id: "nav-facility-activity",
        amiAdminOnly: true,        
      }  
    }
  }
}
