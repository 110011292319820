import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/* Components */
import { Button, Grid, Box, Zoom } from "@material-ui/core";
import CallCenterSearch from "./Search";
import EntityTransactionPanel from "./EntityTransactionPanel";
import VideoFeed from "./VideoFeed";
import EntityCard from "../../EntityCard";
import SearchDetail from "./SearchDetail";
import QueueDetailActions from "./QueueDetailActions";

/* State */
import { shallowEqual, useSelector } from "react-redux";

/* Style */
import { useStyles } from "./QueueDetail.style";
import clsx from "clsx";
import _ from "lodash";
import { useTheme, useMediaQuery } from "@material-ui/core";

/* Hooks */
import useHubContext from "../../../hooks/useHubContext";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useCallCenterActions from "../../../hooks/useCallCenterActions";
import useCurrentUser from "../../../hooks/useCurrentUser";

/* Constants */
import * as c from "../../../constants";

/* Services */
import apiClient from "../../../auth/apiClient";
import CallCenterService from "../../../services/CallCenterService";

/* Utilities */
import moment from "moment";
import useCallCenterTransactionManager from "../Hooks/useCallCenterTransactionManager";
import { useFlags } from "launchdarkly-react-client-sdk";

const callCenterService = new CallCenterService(apiClient);

export const QueueDetail = ({ callID, onCompleted }) => {
  const classes = useStyles();
  const theme = useTheme();

  const hideList = useMediaQuery(theme.breakpoints.down("md"));
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const { hasPermissions } = useHasPermissions();
  const credentialSearchPermission = hasPermissions([
    "callcenter.credentialsearch",
  ]);
  const { callCenterSearchV2 } = useFlags();
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const facilityID = useSelector((state) => state.entities?.ContextID);
  const currentUser = useCurrentUser();
  const queueItem =
    useSelector(
      (state) => _.find(state.callcenter.Queue, { callID }),
      shallowEqual
    ) ?? {};
  const viewingRef = useRef([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [videoConnected, setVideoConnected] = useState(false);
  const [searchDetailContent, setSearchDetailContent] = useState();
  const { reasonConfirmation } = useConfirmationDialog();

  const { getDeviceDetails } = useCallCenterActions();

  const manager = useCallCenterTransactionManager({entityID: queueItem.deviceID});
  
  useEffect(() => {
    getDeviceDetails(
      queueItem.deviceID,
      queueItem.parentEntityID,
      currentUser.UserID
    );
  }, [callID]);

  const onVideoDisconnected = () =>
    handleSetConnection(c.CONNECTION_STATE.DISCONNECTED, false);

  const onVideoConnected = async () => {
    handleSetConnection(c.CONNECTION_STATE.CONNECTED, true);
    try {
      await callCenterService.answerCall(callID, {
        UserID: currentUser.UserID,
        ActivityTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to mark call as answered", {
        variant: "error",
        tag: "FailedToMarkCallAnswered",
      });
    }
  };

  const onVideoConnecting = () =>
    handleSetConnection(c.CONNECTION_STATE.CONNECTING, true);

  const handleCanceled = (_callID) => onCompleted(_callID);

  const handleCompleted = async (_callID, _resolution) => {
    const response = await reasonConfirmation({
      title: "Confirmation",
      message: "Please enter a resolution",
      required: false,
    });
    if (!response) return;

    try {
      callCenterService
        .endCall(
          _callID,
          //_resolution
          {
            UserID: currentUser.UserID,
            ActivityTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
            Resolution: response,
          }
        )
      portalHub.invoke(c.Hub.BROADCAST, {
        Method: "CC_ITEM_REMOVED",
        Target: facilityID,
        IncludeSelf: true,
        Payload: {
          callID: queueItem.callID,
          user: currentUser.EmailAddress,
        },
      });
      onCompleted(_callID); //inform parent
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to end the call", {
        variant: "error",
        TransitionComponent: Zoom,
        tag: "FailedToEndCall",
      });
    }
  };
  const handleSetConnection = (_state, _status) => {
    portalHub.invoke(c.Hub.BROADCAST, {
      Method: "CC_CONNECTION",
      Target: facilityID,
      IncludeSelf: true,
      Payload: {
        callID: queueItem.callID,
        user: _state === 0 ? null : currentUser.EmailAddress,
        state: _state,
      },
    });
    setVideoConnected(_status);
  };

  useEffect(() => {
    viewingRef.current = queueItem.viewing;
  }, [queueItem.viewing]);

  useEffect(() => {
    //portalHub "CC_ITEM_VIEWING" notifications
    //i wanna listen to BROADCASTs of CC_ITEM_VIEWING targeting the facility
    portalHub.invoke(c.Hub.BROADCAST, {
      Method: "CC_ITEM_VIEWING",
      Target: facilityID, //you could target group device, ami, blah, client decides audience
      IncludeSelf: true, //whether or not MY mgmt portal hears what i barked (good for redux doing the work, so i dont hafta)
      Payload: {
        //who cares
        callID: queueItem.callID,
        deviceID: queueItem.deviceID,
        viewing: true,
        user: {
          userID: currentUser.UserID,
          emailAddress: currentUser.EmailAddress,
          username: currentUser.UserName,
        },
      },
    });
    callCenterService
      .updateDetails(queueItem.callID, {
        viewing: [currentUser.EmailAddress, ...(queueItem.viewing ?? [])],
      })
      .catch((err) => console.error(err));

    return () => {
      if (queueItem.connected)
        //this should be my userID, not boolean.  then if (queueitem.connected==currentUser.UserID)
        onVideoDisconnected();

      portalHub.invoke(c.Hub.BROADCAST, {
        Method: "CC_ITEM_VIEWING",
        Target: facilityID,
        IncludeSelf: true,
        Payload: {
          callID: queueItem.callID,
          deviceID: queueItem.deviceID,
          viewing: false,
          user: {
            userID: currentUser.UserID,
            emailAddress: currentUser.EmailAddress,
            username: currentUser.UserName,
          },
        },
      });

      callCenterService
        .updateDetails(queueItem.callID, {
          viewing: viewingRef.current?.filter(
            (x) => x !== currentUser.EmailAddress
          ),
        })
        .catch((err) => console.error(err));
    };
  }, [PortalHubConnected, callID]);

  const handleSearchItemSelected = (item) => {
    if (!item) setSearchDetailContent();
    else setSearchDetailContent({ type: item.type, data: item });
  };

  if (_.isEmpty(queueItem)) return <></>;

  return (
    <div className={clsx([classes.root])}>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={2}
        style={{ marginTop: 0, marginBottom: 10 }}
      >
        <Grid item xs style={{ minWidth: 320, marginRight: 10 }}>
          <Box style={{ position: "relative" }}>
            <EntityCard entityID={queueItem.deviceID}></EntityCard>
          </Box>
        </Grid>
        <Grid
          item
          alignItems="center"
          style={{ justifyContent: "center", margin: "auto", marginRight: 15 }}
        >
          <VideoFeed
            deviceID={queueItem.deviceID}
            width={isXS ? 320 : 266}
            height={isXS ? 240 : 150}
            onConnecting={onVideoConnecting}
            onConnected={onVideoConnected}
            onDisconnected={onVideoDisconnected}
          ></VideoFeed>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {credentialSearchPermission && !callCenterSearchV2 && (
          <Grid item xs={12} md={6}>
            <CallCenterSearch
              entityID={queueItem.deviceID}
              onSelect={handleSearchItemSelected}
            />
            {searchDetailContent && (
              <SearchDetail
                type={searchDetailContent.type}
                data={searchDetailContent.data}
                deviceID={queueItem.deviceID}
                callID={callID}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <EntityTransactionPanel entityID={queueItem.deviceID} callID={callID}/>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ marginTop: 5, marginBottom: 30 }}
      ></Grid>

      <Box
        className={clsx([
          classes.actionsPanel,
          classes.actionPanelsShift,
          "hidelist-" + hideList.toString(),
          "actions-panel",
        ])}
      >
        <div style={{ flex: "1 0 0" }} />
        <QueueDetailActions callID={queueItem.callID} name="Device" />
        <Button
          className={clsx([
            classes.completeButton,
            classes.actionButton,
            "complete-btn",
            "hidelist-" + hideList.toString(),
          ])}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            handleCompleted(callID, null);
          }}
        >
          COMPLETE
        </Button>
        <Button
          className={clsx([
            classes.cancelButton,
            classes.actionButton,
            "cancel-btn",
            "hidelist-" + hideList.toString(),
          ])}
          style={{ marginLeft: 10 }}
          size="small"
          variant="contained"
          color="default"
          onClick={() => {
            handleCanceled(queueItem.callID);
          }}
        >
          CANCEL
        </Button>
      </Box>
    </div>
  );
};
QueueDetail.defaultProps = { callID: undefined, onCompleted: undefined };
QueueDetail.propTypes = {
  callID: PropTypes.number,
  onCompleted: PropTypes.func,
};

export default QueueDetail;
