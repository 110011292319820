import React, { useState, useCallback, useEffect } from "react";
import {
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Grid,
  Button,
} from "@material-ui/core";
import Title from "../../../Title";
import { useStyles } from "./styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope as emailIcon,
  faBell as alertIcon,
  faMobile as textIcon,
  faArrowUpRightAndArrowDownLeftFromCenter as reversingLaneIcon,
  faCircleParking as occupancyIcon,
  faCamera as nonResetIcon,
  faBoxArchive as archiveIcon,
} from "@fortawesome/pro-light-svg-icons";
import { ROUTINE_TYPE, TEXT_ACTION_EVENTS } from "../../../../constants/index";
import AlertForm from "../RoutineForms/AlertForm";
import EmailForm from "../RoutineForms/EmailForm";
import TextActionForm from "../RoutineForms/TextActionForm";
import ReversingLaneActionForm from "../RoutineForms/ReversingLaneActionForm";
import InventoryActionForm from "../RoutineForms/InventoryActionForm";
import { useFlags } from "launchdarkly-react-client-sdk";

const AddRoutineAction = ({
  routine,
  handleClose,
  handleSave,
  action,
  events,
  reversingLanes,
}) => {
  const classes = useStyles();
  const { routinesInventoryArchive } = useFlags();
  const [actionForm, setActionForm] = useState();
  const actionItems = [
    {
      primaryText: "Alert",
      description: "Create an alert on the dashboard",
      icon: <FontAwesomeIcon size={"xl"} icon={alertIcon} />,
      type: ROUTINE_TYPE.Alert,
    },
    {
      primaryText: "Email",
      description: "Send an email to an email address",
      icon: <FontAwesomeIcon size={"xl"} icon={emailIcon} />,
      type: ROUTINE_TYPE.Email,
    },
    {
      primaryText: "Text",
      description: "Send a text to a mobile number",
      icon: <FontAwesomeIcon size={"xl"} icon={textIcon} />,
      type: ROUTINE_TYPE.SMS,
      events: TEXT_ACTION_EVENTS,
    },
    {
      primaryText: "Occupancy",
      description: "Save Occupancy Count Snapshot",
      icon: <FontAwesomeIcon size={"xl"} icon={occupancyIcon} />,
      type: ROUTINE_TYPE.OCCUPANCY_COUNT,
    },
    {
      primaryText: "Non-Reset",
      description: "Save Non-Reset Count Snapshot",
      icon: <FontAwesomeIcon size={"xl"} icon={nonResetIcon} />,
      type: ROUTINE_TYPE.NON_RESET_COUNT,
    },
    {
      primaryText: "Reversing Lane",
      description: "Switch lane mode",
      icon: <FontAwesomeIcon size={"xl"} icon={reversingLaneIcon} />,
      type: ROUTINE_TYPE.ReverseLane,
    },
    {
      primaryText: "Inventory",
      description: "Archive Credentials",
      icon: <FontAwesomeIcon size={"xl"} icon={archiveIcon} />,
      type: ROUTINE_TYPE.Inventory,
    },
  ];

  const callSave = (actionType) => {
    handleSave({ actionTypeID: actionType });
  }

  const handleSelectAction = useCallback(
    (type, action) => {
      switch (type) {
        case ROUTINE_TYPE.Alert:
          setActionForm(
            <AlertForm
              handleCancel={handleClose}
              handleSave={handleSave}
              event={routine?.systemEventId}
              events={events}
              action={action}
            />
          );
          break;
        case ROUTINE_TYPE.Email:
          setActionForm(
            <EmailForm
              handleCancel={handleClose}
              handleSave={handleSave}
              action={action}
            />
          );
          break;
        case ROUTINE_TYPE.SMS:
          setActionForm(
            <TextActionForm
              handleCancel={handleClose}
              handleSave={handleSave}
              action={action}
            />
          );
          break;
        case ROUTINE_TYPE.OCCUPANCY_COUNT:
          callSave(ROUTINE_TYPE.OCCUPANCY_COUNT);
          break;
        case ROUTINE_TYPE.NON_RESET_COUNT:
          callSave(ROUTINE_TYPE.NON_RESET_COUNT);
          break;
        case ROUTINE_TYPE.ReverseLane:
          setActionForm(
            <ReversingLaneActionForm
              handleCancel={handleClose}
              handleSave={handleSave}
              action={action}
              reversingLanes={reversingLanes}
            />
          );
          break;
        case ROUTINE_TYPE.Inventory:
          if (routinesInventoryArchive) {
            setActionForm(
              <InventoryActionForm
                handleCancel={handleClose}
                handleSave={handleSave}
                action={action}
              />
            );
          } else {
            setActionForm(null);
          }
          break;
        default:
          setActionForm(null);
          break;
      }
    },
    [handleClose, handleSave, routine, events, setActionForm]
  );

  useEffect(() => {
    if (action) {
      handleSelectAction(action.actionTypeID, action);
    }
  }, [action, handleSelectAction]);

  return (
    <>
      <Title cssSelector={clsx([classes.actionTitle])} noGutter={false}>
        {action ? "Edit Action" : "Add Action"}
      </Title>
      {!actionForm && (
        <>
          <List className={clsx([classes.actionButtonList])}>
            {actionItems.map((item) => {
              let areEventsToMap = Boolean(item.events);
              // auto-render the action if there are no events to filter this action to
              let render = !areEventsToMap;
              if (areEventsToMap) {
                for (let event in item.events) {
                  if (routine?.systemEventId === item.events[event]) {
                    render = true;
                  }
                }
              }
              if (item.type == ROUTINE_TYPE.Inventory)
                render = (routinesInventoryArchive)

              if (item.type === ROUTINE_TYPE.OCCUPANCY_COUNT) {
                render = !(routine?.actions?.some(x => x.actionTypeID === ROUTINE_TYPE.OCCUPANCY_COUNT));
              }

              if (item.type === ROUTINE_TYPE.NON_RESET_COUNT) {
                render = !(routine?.actions?.some(x => x.actionTypeID === ROUTINE_TYPE.NON_RESET_COUNT));
              }

              return !render ? (
                <></>
              ) : (
                <ListItem
                  data-id={`${item.primaryText}-item`}
                  key={item.primaryText}
                  className={clsx([classes.actionListItem])}
                  button
                  onClick={() => {
                    handleSelectAction(item.type);
                  }}
                >
                  <ListItemAvatar className={clsx([classes.listIcon])}>
                    {item.icon}
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.primaryText}
                    className={clsx([classes.listText])}
                  />
                  <ListItemText
                    primary={item.description}
                    className={clsx([classes.listDescription])}
                  ></ListItemText>
                </ListItem>
              );
            })}
          </List>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className={clsx([classes.buttonContainer])}
          >
            <Button
              className={clsx([classes.closeBtn])}
              data-id="closeBtn"
              name="close"
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          </Grid>
        </>
      )}
      {actionForm}
    </>
  );
};
export default AddRoutineAction;
