import { useEffect, useRef } from "react";

/* State */
import { fillEntityLevel } from "../../state/slices/entities";
import { addToQueue } from "../../state/slices/callcenter";
import { useDispatch } from "react-redux";

/* Custom Hooks */
import useHubContext from "../useHubContext";
import useCurrentUser from "../useCurrentUser";
import useDeviceIDs from "../useDeviceIDs";
import useScopedPermissions from "../useScopedPermissions";
import { useSelector } from "react-redux";
import useCurrentFacility from "../useCurrentFacility";

/* Constants*/
import { DEVICE_STATE_READ_TOPIC, PORTAL_TRIGGER } from "../../constants";

const statePendingCalls = [];
const queuedDevicesThatNeedLoadedState = [];

export default function useCallCenterActions() {
  const dispatch = useDispatch();
  const { portalHub } = useHubContext();
  const currentUser = useCurrentUser();
  const userID = useRef();
  userID.current = currentUser.UserID;
  const allDeviceIds = useDeviceIDs();
  const deviceIdRef = useRef();
  deviceIdRef.current = allDeviceIds;
  const scopedPermissions = useScopedPermissions();
  const scopedPermissionsRef = useRef();
  scopedPermissionsRef.current = scopedPermissions;
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId);
  const scopeAwareFacilityIDRef = useRef();
  scopeAwareFacilityIDRef.current = scopeAwareFacilityID;

  useEffect(() => {
    handleCallsAwaitingToJoinQueue();
    handleCallsInQueueThatNeedState();
  }, [scopedPermissions]);

  /* Responsible for adding calls to the queue and fetching their device state */
  const handleCallsAwaitingToJoinQueue = () => {
    const pendingClone = JSON.parse(JSON.stringify(statePendingCalls));
    pendingClone.forEach((call) => {
      const permissions = scopedPermissions[call.parentEntityID];
      if (!permissions) return;
      if (permissions.includes("callcenter.view")) {
        portalHub.invoke(PORTAL_TRIGGER, {
          entityID: call.deviceID,
          method: "retrieve",
          action: "retrieve",
          topic: DEVICE_STATE_READ_TOPIC,
        });
        dispatch(addToQueue(call));
        statePendingCalls.splice(
          statePendingCalls.findIndex(
            (stateCall) => stateCall.parentEntityID === call.parentEntityID
          ),
          1
        );
      }
    });
  };

  /* Responsible for fetching device state of already queued calls */
  const handleCallsInQueueThatNeedState = () => {
    const pendingClone = JSON.parse(
      JSON.stringify(queuedDevicesThatNeedLoadedState)
    );
    pendingClone.forEach((device) => {
      const permissions = scopedPermissions[device.parentEntityID];
      // the device is already in the queue at this point, so we're simply checking to see if we have state loaded for this given parent
      if (!permissions) return;
      portalHub.invoke(PORTAL_TRIGGER, {
        entityID: device.deviceID,
        method: "retrieve",
        action: "retrieve",
        topic: DEVICE_STATE_READ_TOPIC,
      });

      queuedDevicesThatNeedLoadedState.splice(
        queuedDevicesThatNeedLoadedState.findIndex(
          (queuedDeviceID) => queuedDeviceID === device.deviceID
        ),
        1
      );
    });
  };

  const addCallToQueue = (call) => {
    if (deviceIdRef.current?.find((x) => x == call.deviceID) == undefined) {
      console.log(
        `device ${call.deviceID} doesn't exist in context for call center. Fetching state...`
      );
      // Device doesn't exist in store
      // Get tree using parentID
      statePendingCalls.push(call);
      dispatch(
        fillEntityLevel({
          entityID: scopeAwareFacilityIDRef.current || call.parentEntityID,
          userID: userID.current,
        })
      );
    } else {
      // Device exists in store
      // check permission for currentUser
      const permissions = scopedPermissionsRef.current[call.parentEntityID];
      if (permissions && permissions.includes("callcenter.view")) {
        // add call to queue
        dispatch(addToQueue(call));
      }
    }
  };

  /* Used to fetch the entity level for the given deviceID if it does not exist */
  const getDeviceDetails = (deviceID, parentEntityID, currentUserID) => {
    if (deviceIdRef.current?.find((x) => x == deviceID) == undefined) {
      console.log(
        `device ${deviceID} doesn't exist in context for call center. Fetching state...`
      );
      // Device doesn't exist in store
      // Get tree using parentID
      queuedDevicesThatNeedLoadedState.push({
        deviceID,
        parentEntityID,
      });
      dispatch(
        fillEntityLevel({
          entityID: parentEntityID,
          userID: currentUserID,
        })
      );
    }
  };

  return {
    addCallToQueue,
    getDeviceDetails,
  };
}
