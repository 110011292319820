import {
  CREDENTIAL_UPDATED,
  FORWARD_MESSAGE_BODY_ONLY,
  PORTAL_TRIGGER,
} from "../constants";
import useHubContext from "./useHubContext";

const useActivity = () => {
  const { portalHub } = useHubContext();

  const recordActivity = async ({
    credentialID,
    deviceID,
    activityDate,
    activityType,
    resultCode,
    accessGroupID,
    entityID,
    ticketmasterInfo,
    prepaidInfo,
  }) => {
    const activityToRecord = {
      CredentialID: credentialID,
      DeviceID: deviceID,
      ActivityDate: activityDate,
      ActivityType: activityType,
      ResultCode: resultCode,
      EgressAllowed: true,
      AccessGroupID: accessGroupID,
      TicketmasterInfo: ticketmasterInfo,
      PrepaidInfo: prepaidInfo       
    };
    return await portalHub.invoke(PORTAL_TRIGGER, {
      entityID: entityID,
      topic: CREDENTIAL_UPDATED,
      action: FORWARD_MESSAGE_BODY_ONLY,
      message: JSON.stringify(activityToRecord),
    });
  };

  return { recordActivity };
};

export default useActivity;
