import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Button, MenuItem } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { useStyles } from "./style";
import * as Yup from "yup";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextField } from "formik-material-ui";
import InputField from "../InputField";
import CredentialService from "../../../services/CredentialService";
import apiClient from "../../../auth/apiClient";
import clsx from "clsx";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import InlineValidationOfferForm from "./inline";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import RateSelector from "../../RateSelector";
import { isUndefined } from "lodash";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../constants";
import { isDiscountType } from "../Validation/Modules/utilities";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { shallowEqual } from "react-redux";

const credentialService = new CredentialService(apiClient);

const nonFGValidationOfferSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  rate: Yup.string().when("type", {
    is: value => value === "Rate Switch",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().nullable()
  }),
  amount: Yup.number().when("type", {
    is: value => value !== "Rate Switch",
    then: Yup.number().required("Required"),
    otherwise: Yup.number().nullable()
  }),
});

const AcceptedAtSchema = Yup.object().shape({
  acceptedAtEntityIDs: Yup.array().required("Required"),
});

export const validationTypes = [
  {
    value: "Amount",
    label: "$ Off Amount"
  },
  {
    value: "Percentage",
    label: "% Off Amount"
  },
  {
    value: "Flat Fee",
    label: "$ Flat Fee"
  },
  {
    value: "Rate Switch",
    label: "Rate Switch"
  },
  {
    value: "Adjust Entry Time",
    label: "Entry Time Change (mins)"
  },
  {
    value: "Adjust Exit Time",
    label: "Exit Time Change (mins)"
  }
];

const ValidationOfferForm = ({
  key,
  index,
  validationAccountData,
  contractHolderID,
  data,
  onCancel,
  onSubmit,
  inline,
  onDelete
}) => {
  if (data.amount && isDiscountType(data.type))
    data.amount = Math.abs(data.amount);
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const scopeAwareFacilityID = facilityGroupID || facilityID;
  const userType = useSelector(state => state.user.UserType);

  const ValidationOfferSchema = facilityGroupID ? nonFGValidationOfferSchema.concat(AcceptedAtSchema) : nonFGValidationOfferSchema;

  const { hasPermissions } = useHasPermissions();
  const editValidationOffers =
    hasPermissions(["validationaccounts.edit"]) &&
    userType != USER_TYPES.ValidationAccount;
  const addValidationOffers =
    hasPermissions(["validationaccounts.add"]) &&
    userType != USER_TYPES.ValidationAccount;
  const deleteValidationOffers =
    hasPermissions(["validationaccounts.delete"]) &&
    userType != USER_TYPES.ValidationAccount;
  const [validationOffer, setValidationOffer] = useState({
    ...data,
    contractHolderID: parseInt(validationAccountData.contractHolderID),
    entityID: scopeAwareFacilityID,
    contractID: validationAccountData.contractID
  });
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();

  const deleteValidationOffer = async validationOfferID => {
    const response = await showDialog({
      title: "Delete Validation Offer",
      message: `Are you sure you want to remove this Validation Offer from this Validation Account?`,
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" }
      ]
    });

    if (response === "No") return;

    try {
      await credentialService.DeleteValidationOffer(validationOfferID);
    } catch (err) {
      enqueueSnackbar("Failed to delete Validation Offer", {
        variant: "error",
        tag: "FailedToDeleteValidationOffer"
      });
      return;
    }

    enqueueSnackbar("Successfully deleted Validation Offer", {
      variant: "success"
    });
    onDelete(validationOfferID);
  };

  const createValidationOffer = async values => {
    if (isUndefined(contractHolderID)) {
      enqueueSnackbar(
        "No validation account associated with this validation offer",
        {
          variant: "error",
          tag: "NoValidationAccountAssoicatedWithOffer"
        }
      );
      return;
    }
    try {
      values.amount = isDiscountType(values.type)
        ? -(Math.abs(values.amount))
        : Math.abs(values.amount);

      await credentialService.CreateValidationOffer(values);
      enqueueSnackbar("Successfully created validation offer", {
        variant: "success"
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to create validation offer", {
        variant: "error",
        tag: "FailedToCreateValidationOffer"
      });
      return;
    }

    onSubmit();
  };

  const updateValidationOffer = async values => {
    try {
      values.amount = isDiscountType(values.type)
        ? -(Math.abs(values.amount))
        : Math.abs(values.amount);

      await credentialService.UpdateValidationOffer(values);
      enqueueSnackbar("Successfully updated validation offer", {
        variant: "success"
      });
    } catch (err) {
      if (err.response.status === 400)
        enqueueSnackbar(err.response.data, {
          variant: "error",
          tag: "FailedToUpdateValidationOffer"
        });
      else
        enqueueSnackbar("Failed to update validation offer", {
          variant: "error",
          tag: "FailedToUpdateValidationOffer"
        });
      return;
    }
  };

  if (inline === true) {
    return (
      <InlineValidationOfferForm
        key={key}
        index={index}
        data={validationOffer}
        contractHolderID={contractHolderID}
        contractID={validationAccountData.contractID}
        onFormSubmit={createValidationOffer}
        onDelete={deleteValidationOffer}
        updateValOffer={updateValidationOffer}
        schema={ValidationOfferSchema}
      />
    );
  } else {
    //TODO: Update the validation offer form that is not inline when it needs to be used
    return (
      <Box className={classes.root} key={key}>
        <Formik
          onSubmit={async values => {
            await createValidationOffer(values);
          }}
          enableReinitialize
          validateOnChange={false}
          initialValues={validationOffer}
          validationSchema={ValidationOfferSchema}
        >
          {({ submitForm, values }) => (
            <Form className={classes.formContainer}>
              <InputField
                component={TextField}
                className={classes.flexInput}
                fieldName="validationOfferName"
                labelName="Validation Name"
                name="name"
                disabled={!editValidationOffers}
              />
              <InputField
                id="validation-type"
                className={clsx([
                  "val-type",
                  classes.validationType,
                  classes.flexInput
                ])}
                component={TextField}
                value={validationOffer?.validationTypeID}
                name="type"
                label="Type"
                select
                variant="outlined"
                SelectProps={{
                  SelectDisplayProps: {
                    "data-testid": "validation-type"
                  }
                }}
                disabled={!editValidationOffers}
              >
                {validationTypes?.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type.value}>
                      {type.label}
                    </MenuItem>
                  );
                })}
              </InputField>
              {values.validationTypeID?.value == 4 ? (
                <RateSelector
                  entityID={facilityID}
                  name="rateBlobID"
                  disabled={!editValidationOffers}
                />
              ) : (
                <Field
                  component={TextField}
                  className={clsx(["input-amt", classes.input])}
                  variant="outlined"
                  name="amount"
                  label="Amount"
                  disabled={!editValidationOffers}
                />
              )}
              <Grid
                className={clsx(["btn-container", classes.btnContainer])}
                container
                spacing={2}
              >
                <Grid item>
                  <Button
                    className={clsx("btn-create")}
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    disabled={!editValidationOffers}
                  >
                    Create
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={clsx("btn-cancel")}
                    startIcon={<CancelIcon />}
                    variant="contained"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
};

ValidationOfferForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onDelete: () => {},
  data: {},
  inline: false,
  validationAccountData: {}
};

ValidationOfferForm.propTypes = {
  data: PropTypes.shape({
    contractHolderID: PropTypes.number
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  inline: PropTypes.bool,
  onDelete: PropTypes.func,
  validationAccountData: PropTypes.object
};

export default ValidationOfferForm;
