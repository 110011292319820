import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useSettingDispatchChange } from "./index";
import useHasPermissions from "../../../hooks/useHasPermissions";
import useEntitySettings from "../../../hooks/useEntitySettings";

const useFormHandlers = (setter, initialValues, entityID, entityType) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const { dispatchSetting, dispatchDeleteSetting, dispatchBulkSettings } = useSettingDispatchChange();
  const { hasPermissions } = useHasPermissions();
  const { broadcastSettingChange } = useEntitySettings();

  const EditFacilitySettingsPermission = hasPermissions(
    ["overview.facilities.editsettings"],
    false,
    entityID
  );
  const EditValetAreaSettingsPermission = hasPermissions(
    ["overview.valetarea.editsettings"],
    false,
    entityID
  );
  const EditAreaSettingsPermission = hasPermissions(
    ["overview.area.editsettings"],
    false,
    entityID
  );
  const EditDeviceSettingsPermission = hasPermissions(
    ["overview.devices.editsettings"],
    false,
    entityID
  );
  const ViewUsersPermission = hasPermissions(["users.view"], false, entityID);
  const GPIOConfigurationPermission = hasPermissions(
    ["devices.gpio.configuration"],
    false,
    entityID
  );
  const ViewOccupancyPermission = hasPermissions(
    ["editoccupancy"],
    false,
    entityID
  );
  const ViewCreditCardGatewayPermission = hasPermissions(
    ["ccgateway.view"],
    false,
    entityID
  );
  const ViewTaxesPermission = hasPermissions(["rates.view"], false, entityID);

  const handleToggle = async (values, errors, e) => {
    let targetName = e.target.name;
    let targetValue = !values[targetName];
    if (errors[targetName]) {
      setter(targetName, initialValues[targetName]);
      return;
    }

    //perceive faster render
    setter(targetName, targetValue);
    if (!(await dispatchSetting(entityID, targetName, targetValue))) {
      setter(targetName, initialValues[targetName]);
      enqueueSnackbar(
        "Unable to change the [" + targetName + "] setting.  Please try again.",
        { variant: "error", tag: "Unable ToChangeSetting" }
      );
    } else
      broadcastSettingChange({
        target: entityID,
        settingName: targetName,
        settingValue: targetValue,
      });
  };

  const handleInputChange = async (errors, e) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;
    if (targetValue === initialValues[targetName])
      //no changes
      return;
    if (errors[targetName]) {
      setter(targetName, initialValues[targetName]); //not valid
      return;
    }

    //perceive faster render
    setter(targetName, targetValue);
    if (!(await dispatchSetting(entityID, targetName, targetValue))) {
      setter(targetName, initialValues[targetName]);
      enqueueSnackbar(
        "Unable to change the [" + targetName + "] setting.  Please try again.",
        { variant: "error", tag: "UnableToChangeSetting" }
      );
    }
  };

  const handleSelectChange = async (errors, e, selected) => {
    let targetName = e.target.name;
    let targetValue = selected.props.value;

    setter(targetName, selected.props.value); //make it look fast
    if (targetValue == initialValues[targetName]) return; //if its the same, lets not, shall we?
    if (errors[targetName]) {
      setter(targetName, initialValues[targetName]);
      return;
    }

    // Need to update the initial value in the case that the user stays in the form, changes setting which
    // generates a setting change event, then goes back to the intial value. In this case we would not get
    // a setting change event if we did not update the initial value.
    initialValues[targetName] = targetValue;

    //make it look fast
    //if we weren't successful in dispatching, revert value
    if (!(await dispatchSetting(entityID, targetName, targetValue))) {
      setter(targetName, initialValues[targetName]);
      enqueueSnackbar(
        "Unable to change the [" + targetName + "] setting.  Please try again.",
        { variant: "error", tag: "UnableToChangeSetting" }
      );
      return false;
    } else return true;
  };

  const handleResetChange = async (targetName, targetValue) => {
    setter(targetName, targetValue);
    if (!(await dispatchDeleteSetting(entityID, targetName, targetValue))) {
      setter(targetName, initialValues[targetName]);
      enqueueSnackbar(
        "Unable to delete the [" + targetName + "] setting.  Please try again.",
        { variant: "error", tag: "UnableToChangeSetting" }
      );
    }
  };

  const canRenderFieldByMode = (fieldName, deviceMode) => {
    const canRender = canRenderField(fieldName);
    if (!canRender) return false;
    if (deviceMode === "door") {
      switch (fieldName.toLowerCase()) {
        case "devicemode":
        case "intercomprovider":
          return true;
        default:
          return false;
      }
    } else if (deviceMode === "hotel interface") {
      switch (fieldName.toLowerCase()) {
        case "devicemode":
          return true;
        default:
          return false;
      }
    } else if (deviceMode === "exit") {
      switch (fieldName.toLowerCase()) {
        case "payonentry":
          return false;
        default:
          return true;
      }
    } else if (deviceMode === "kiosk") {
      switch (fieldName.toLowerCase()) {
        case "payonentry":
        case "valetdeviceid":
        case "lpr":
        case "allowtransactionswhilegateraised":
        case "devicenesting":
          return false;
        default:
          return true;
      }
    } else if (deviceMode === "diagnostic") {
      switch (fieldName.toLowerCase()) {
        case "devicenesting":
        case "logosandbanners":
          return false;
        default:
          return true;
      }
    } else return true;
  };

  const canRenderField = (fieldName) => {
    if (fieldName === "") return true;
    if (entityType?.toLowerCase() === "device") {
      switch (fieldName?.toLowerCase()) {
        case "devicemode":
        case "rates":
        case "peripherals":
        case "devicedisabled":
        case "devicehighcontrast":
        case "laneclosed":
        case "backouttimeout":
        case "toidletimeout":
        case "centralpaytrxtimeout":
        case "displaylotfull":
        case "allowtransactionswhilegateraised":  
        case "autoprintreceipt":
        // case "printerretracttimeout":
        case "ticket.header":
        case "ticket.footer":
        case "receipt.header":
        case "receipt.footer":
        case "issueticketwhilefull":
        case "duplicatecredentialreaddelay":
        case "intercomprovider":
        case "payonentry":
        case "valetdeviceid":
        case "lpr":
        case "ticketmastergatename":
        case "receiptform":
        case "ticketform":
        case "devicenesting":
        case "logosandbanners":
          return EditDeviceSettingsPermission;
        case "ccdeviceid":
          return ViewCreditCardGatewayPermission;
        case "gpio":
          return GPIOConfigurationPermission;
        default:
          return false;
      }
    } else if (entityType?.toLowerCase() === "facility") {
      switch (fieldName?.toLowerCase()) {
        case "schedulereportconfigurationform":
        case "displaylotfull":
        case "allowtransactionswhilegateraised":
        case "backouttimeout":
        case "thirdpartyrate":
        case "toidletimeout":
        case "centralpaytrxtimeout":
        case "thirdpartyaccessgroup":
        case "emailtemplate":
        case "emailform":
        case "mobilepayform":
        case "thirdpartyform":
        case "thirdparties":
        // case "printerretracttimeout":
        case "autoprintreceipt":
        case "ticket.header":
        case "ticket.footer":
        case "receipt.header":
        case "receipt.footer":
        case "issueticketwhilefull":
        case "duplicatecredentialreaddelay":
        case "intercomprovider":
        case "shortchangeenabled":
        case "shortchangeinstructions":
        case "payonentry":
        case "lostticketallowvalidations":  
        case "ticketmasterurl":
        case "ticketmastertoken":
        case "ticketmastervenueid":
        case "lpr":
        case "ticketmastergatename":
        case "facilityvalidations":
        case "receiptform":
        case "ticketform":
        case "logosandbanners":
          return EditFacilitySettingsPermission;
        case "occupancy":
          return ViewOccupancyPermission;
        case "manageusers":
          return ViewUsersPermission;
        case "creditcardgateway":
          return ViewCreditCardGatewayPermission;
        case "cardformats":
          return EditFacilitySettingsPermission;
        case "taxsettings":
          return ViewTaxesPermission;
        case "reversinglanes":
          return EditFacilitySettingsPermission;
        default:
          return false;
      }
    } else if (entityType?.toLowerCase() === "facilitygroup") {
      switch (fieldName?.toLowerCase()) {
        // Might need to replace with permissions for FG in later phase
        // Currently no permissions for FG.
        case "emailform":
          return EditFacilitySettingsPermission;
        case "creditcardgateway":
          return ViewCreditCardGatewayPermission;
        case "valetareaticketsettings":
        case "arrivalsettings":
        case "requestsettings":
          return EditValetAreaSettingsPermission;
        default:
          return false;
      }
    } else if (entityType?.toLowerCase() === "valetarea") {
      switch (fieldName?.toLowerCase()) {
        case "valetareaticketsettings":
        case "arrivalsettings":
        case "requestsettings":
          return EditValetAreaSettingsPermission;
        default:
          return false;
      }
    } else if (entityType?.toLowerCase() === "area") {
      switch (fieldName?.toLowerCase()) {
        case "intercomprovider":
          return EditAreaSettingsPermission;
        default:
          return false;
      }
    }

    return false;
  };

  const canDisplayResetIcon = (fieldName) => {
    if (entityType?.toLowerCase() === "device") {
      switch (fieldName?.toLowerCase()) {
        case "displaylotfull":
        case "backouttimeout":
        case "toidletimeout":
        case "centralpaytrxtimeout":
        case "autoprintreceipt":
        case "issueticketwhilefull":
        case "ticket.header":
        case "ticket.footer":
        case "receipt.header":
        case "receipt.footer":
        case "duplicatecredentialreaddelay":
          return true;
      }
    }
    return false;
  };

  const handleInputSave = async (name, data) => {
    if (!(await dispatchSetting(entityID, name, data))) {
      setter(name, initialValues[name]);
      enqueueSnackbar(
        "Unable to change the [" + name + "] setting.  Please try again.",
        { variant: "error", tag: "UnableToChangeSetting" }
      );
    }
  }

  const handleBulkSettingUpdate = async (targetNameList, values) => {
    if (!(await dispatchBulkSettings(targetNameList, values, entityID))) {
      enqueueSnackbar(
        "Unable to change settings. Please try again.",
        { variant: "error", tag: "UnableToChangeSetting" }
      );
      return false;
    } else {
      return true;
    }
  }

  return {
    handleToggle,
    handleInputChange,
    handleSelectChange,
    canRenderField,
    handleResetChange,
    canDisplayResetIcon,
    canRenderFieldByMode,
    handleInputSave,
    handleBulkSettingUpdate
  };
};

export default useFormHandlers;
