import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    padding: "1px",
    "& p": {
      margin: theme.spacing(0.5),
      paddingTop: "1px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "unset",
      display: "block",
    },
  },
  ticketValidationRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flex: "0 1 100%",
  },
  ticketInfo: {
    maxHeight: "60px",
    margin: "0px",
  },
  ticketIdText: {},
  validate: {
    width: "100%",
    marginTop: theme.spacing(1),
    flex: 1,
    display: "inline-flex",
    justifyContent: "space-between",
  },
  validationDescriptionContainer: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
  appliedValidation: {
    maxWidth: "75%",
  },
  removeButton: {
    margin: theme.spacing(0.5),
    height: "40px",
    flexBasis: "75px",
    alignSelf: "flex-end",
  },
  applyButton: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    maxHeight: "40px",
    alignSelf: "center",
    flexBasis: "75px",
  },
  multiValDropdown: {
    flex: 1,
  },
  validations: {
    margin: theme.spacing(0.5),
  },
  validationGrid: {
    marginTop: '16px',
  },
  memoText: {
    color: "grey",
    overflowWrap: 'break-word',
  },
}));
