import React, {useState} from "react";
import {
  Button,
  Zoom,
  DialogActions,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import apiClient from "../../auth/apiClient";
import { endCashierShift, cashierShiftSetEndWhenAfterTransaction } from "../../state/slices/shiftSession/shiftSession";
import CashierShiftsService from "../../services/CashierShiftsService";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useHistory } from "react-router-dom";

const cashierShiftsService = new CashierShiftsService(apiClient);

const EndShiftPulseDialog = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [endingShift, setEndingShift] = useState(false);
  const currentUser = useCurrentUser();
  const shiftSession = useSelector((state) => state.shiftSession);
  const enqueueSnackbar = useEnqueueSnackbar();
  const history = useHistory();

  const handleEndShift = async () => {
    if (!endingShift) {
      try {
        setEndingShift(true);
        const endShiftResult = await cashierShiftsService.endShift(shiftSession.shiftID, currentUser.UserID);
        if (endShiftResult?.status === 204) {
          if (!currentUser?.isAdmin === true) history.push("/logout");
          dispatch(endCashierShift());
        } else {
          enqueueSnackbar(`Failed to end shift`, {
            variant: "error",
            TransitionComponent: Zoom,
            tag: "endShiftFailed",
          });
        }
      } catch {
        enqueueSnackbar(`Failed to end shift`, {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "endShiftFailed",
        });
      }
      setEndingShift(false);
      onClose();
    }
  }

  return (
    <>
      <DialogTitle className={clsx(classes.pulseTitle, "dialog-title")}>
        Are you sure you want to end your shift?
      </DialogTitle>
      <Divider style={{ marginBottom: 15, marginTop: -10 }} />
      <DialogActions className={clsx(classes.pulseActions)}>
          <Button
            className={clsx("Yes")}
            onClick={() => {
              if (shiftSession.transaction.started) {                
                dispatch(cashierShiftSetEndWhenAfterTransaction());
                onClose();
              } else {
                handleEndShift();
              }              
            }}
            variant="contained"
            color="primary"
            disabled={endingShift}
            role="yes-button"
          >
            Yes
          </Button>
          <Button
            className={clsx("No")}
            onClick={onClose}
            color="secondary"
            variant="contained"
            role="no-button"
          >
            No
          </Button>
      </DialogActions>
    </>
  );
};

export default EndShiftPulseDialog;
