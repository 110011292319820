import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  numberLabel: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  nameLabel: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  severityLabel: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  severityDropdown: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  laneLabel: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minWidth: "300px",
  },
  modeLabel: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },    
  laneDropdown:{
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },  
  modeDropdown:{
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  actionContainer: {
    marginTop: 20,
  },
  actionHeader: {
    margin: "10px",
  },
  actionIcon: {
    margin: "10px",
    fontWeight: "normal",
  },
  actionText: {
    margin: "10px",
    marginTop: "1px",
    fontWeight: "normal",
  },
  cancelBtn: {
    float: "right",
  },
  saveBtn: {
    float: "right",
  },
  drawerContainer: {
    padding: theme.spacing(2),
    minWidth: "400px",
  },
  buttonContainer: {
    margin: theme.spacing(2),
  },
  btnControl: {
    margin: theme.spacing(1),
    float: "right",
  },
  tableContainer: {
    overflowY: "auto",
    overflowX: "auto",
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  DrawerTitle: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      textAlign: "center",
    },
  },
  table: {
    tableLayout: "fixed",
    [theme.breakpoints.down("md")]: {
      tableLayout: "auto",
    },
  },
  tableCells: {
    alignContent: "center",
    justifyContent: "center",
  },
  actionTableCell: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "200px",
    },
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  BoardDropdown: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  pinField: {
    paddingLeft: theme.spacing(1),
  },
  addButtonGrid: {
    height: "50px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  radioControls: {
    marginTop: theme.spacing(1),
  },
  systemEventLabel: {
    paddingTop: "10px",
    paddingLeft: "18px",
  },
  selectError: {
    color: "#f44336",
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
  },
  mobileNumber: {
    maxWidth: "17em",
  },
  mobileName: {
    minWidth: "17em",
  },
  formError: {
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
    maxWidth: "17em",
  },
}));
