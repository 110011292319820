import React, { useEffect, createContext } from "react";

/* State */
import store from "../../state/store";
import { Provider } from "react-redux";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

/* -- entity state -- */
import {
  loadEntities,
  getPermissionsAtEntity,
  FindEntity,
  setSetting,
  setSettings,
} from "../../state/slices/entities";

/* -- facility group state -- */
import { loadScope } from "../../state/slices/entityScope";

/* -- user state -- */
import {
  setUserID,
  setUserEmail,
  setUsername,
  setFirstname,
  setLastname,
  setUserType,
  setIsAdmin,
} from "../../state/slices/user";

/* -- poe state -- */
import { fetchOffers } from "../../state/slices/POE/payOnEntry";

/* -- cashier state -- */
import { fetchRoamingCCTerminals } from "../../state/slices/cashier/cashier";
import {
  endCashierShift,
  fetchCashierShift,
} from "../../state/slices/shiftSession/shiftSession";

/* -- feature flagging state -- */
import {
  fetchFlags,
  clearFlags,
} from "../../state/slices/featureflags/featureflags";

/* Hooks */
import useAuthContext from "../../hooks/useAuthContext";
import useHubContext from "../../hooks/useHubContext";
import useCallCenterListeners from "../../hooks/useCallCenterListeners";
import useCurrentFacility from "../../hooks/useCurrentFacility";

/* Components */
import { Box, CircularProgress, Typography } from "@material-ui/core";

/* Constants */
import * as c from "../../constants";

/* Services */
import apiClient from "../../auth/apiClient";
import UserService from "../../services/UserService";
import AppActionProvider from "./AppActionProvider";
import useDeviceConnectionManager from "../../hooks/useDeviceConnectionManager";
import useDeviceStateLoader from "../../hooks/useDeviceStateLoader";

const userService = new UserService(apiClient);

export const AppStoreContext = createContext();

const AppStoreProvider = ({ children }) => {
  return (
    <AppStoreContext.Provider>
      <Provider store={store}>
        <AppStoreContent>{children}</AppStoreContent>
      </Provider>
    </AppStoreContext.Provider>
  );
};

const AppStoreContent = ({ children }) => {
  const { authReducer, setCurrentFacility } = useAuthContext();
  const [authData] = authReducer;
  const { portalHub, Connected: PortalHubConnected } = useHubContext();

  const dispatch = useDispatch();
  const {} = useDeviceConnectionManager({portalHub, dispatch});

  const contextID = useSelector((state) => state.entities?.ContextID);
  const currentUserId = authData.userSession?.idToken?.payload?.userid;
  const { facilityID, facilityName } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const loadedPermissions = useSelector(
    (state) =>
      FindEntity(state?.entities?.EntityList ?? [], contextID)?.permissions,
    shallowEqual
  );

  const allEntities = useSelector((state) =>state.entities?.EntityIDsRaw, shallowEqual);
  // Provide slice usage as a hook to keep this file smaller, otherwise we will have a 1 million line AppStoreProvider
  const {
    handleBroadcast: handleCallCenterRequests,
    initializeQueue,
  } = useCallCenterListeners();
  
  const {} = useDeviceStateLoader({portalHub, dispatch, contextID});
  
  const loadCurrentUserDetails = async () => {
    if (authData.userSession?.idToken?.payload) {
      let userInfo = null;
      try {
        var queryUser = await userService.getUser(currentUserId);
        userInfo = queryUser.data;
        delete userInfo.entities; //free up space
        delete userInfo.validationAccounts; //since this call returns a lot of data in here
      } catch {
        console.log(
          "AppStoreProvider: getCurrentUser, could not load user.",
          queryUser
        );
      }
      const {
        email,
        userid,
        firstName,
        lastName,
        userType,
      } = authData.userSession.idToken.payload;
      dispatch(setUserID(userid));
      dispatch(setUserEmail(email));
      //if we got it from the API, use that - otherwise use the information from the JWT
      dispatch(
        setUsername(
          userInfo?.lastName
            ? `${userInfo.firstName} ${userInfo.lastName}`
            : `${firstName} ${lastName}`
        )
      );
      dispatch(
        setFirstname(
          userInfo?.firstName
            ? `${userInfo.firstName}`
            : `${firstName}`
        )
      );
      dispatch(
        setLastname(
          userInfo?.lastName
            ? `${userInfo.lastName}`
            : `${lastName}`
        )
      );
      //dispatch(setUsername(`${firstName} ${lastName}`));
      dispatch(setUserType(userType));
      dispatch(setIsAdmin(authData.isAdmin));
    } else {
      dispatch(setUserID(null));
      dispatch(setUserEmail(null));
      dispatch(setUsername(null));
      dispatch(setFirstname(null));
      dispatch(setLastname(null));
      dispatch(setUserType(null));
      dispatch(setIsAdmin(false));
    }
  };

  useEffect(() => {
    //portalhub broadcast
    if (portalHub.isOpen) {
      portalHub.subscribe("BROADCAST", async (packet) => {
        //console.log("AppStoreProvider|SIGNAL-R [BROADCAST]: ", packet);
        handleCallCenterRequests(packet);
        // TODO: add other request handlers for whatever else may be listening for a general BROADCAST
      });
    }
    return () => {
      portalHub.unsubscribe("BROADCAST");
    };
  }, [PortalHubConnected]);

  useEffect(() => {
    if (!PortalHubConnected) return;

    // Note https://amanomcgann.atlassian.net/browse/AO-11685
    // when devices are ported to use the generic entity endpoint this
    // subscription should change to ENTITY_CREATED checking entity type
    // Presumably, we will want to add a reload of the entity tree as well
    portalHub.subscribe("DEVICE_CREATED", async (message) => {
      SendJoinGroupMessage(message.deviceID);
    });

    return () => {
      portalHub.unsubscribe("DEVICE_CREATED");
    };
  }, [PortalHubConnected]);

  const SendJoinGroupMessage = async (entityId) => {
    try {
      await portalHub.invoke(c.JOIN_GROUP, {
        entityId: entityId,
      });
    } catch (error) {
      console.error("Couldn't Join Group in Hub", entityId, error);
    }
  };

  //portalhub listener for messages. 
  // All topic messages that come in from portal hub need to be subscribed to invoke there clients
  useEffect(() => {
    
    console.log("listening")
    //portalhub listener for messages.
    if (portalHub.isOpen) {
      c.ALL_TOPICS.forEach(topic =>{
        portalHub.subscribe(`${topic}`, async (message) => {}, true);
      })
      
      if(allEntities){
        allEntities.forEach(entity =>{
          if(entity){
            portalHub.subscribe(`${entity.entityid}`, async (message) => {}, true);
            portalHub.subscribe(`${entity.entityid}_${c.NON_RESET_COUNTS_TOPIC}`, async (message) => {}, true);
            portalHub.subscribe(`${entity.entityid}_${c.REVENUE_WIDGET}`, async (message) => {}, true);
            portalHub.subscribe(`${entity.entityid}_${c.CALL_CENTER_CAPTURE_UPLOADED}`, async (message) => {}, true);
            portalHub.subscribe(`${entity.entityid}_${c.OCCUPANCY_DEMAND_CHANGE}`, async (message) => {}, true);
            portalHub.subscribe(`${entity.entityid}_GPIO`, async (message) => {}, true);
            portalHub.subscribe(`${entity.entityid}_ACTIVITY_CHANGE`, async (message) => {}, true);
            portalHub.subscribe(`${entity.entityid}_${c.REVENUE_DASHBOARD_DATA_CHANGE}`, async (message) => {}, true);
          }
        })
      }
    }
    return () => {
      if (portalHub.isOpen){
        c.ALL_TOPICS.forEach(topic =>{
          portalHub.unsubscribe(`${topic}`, async (message) => {}, true);
        })

        if(allEntities){
          allEntities.forEach(entity =>{
            if(entity){
              portalHub.unsubscribe(`${entity.entityid}`);
              portalHub.unsubscribe(`${entity.entityid}_${c.NON_RESET_COUNTS_TOPIC}`);
              portalHub.unsubscribe(`${entity.entityid}_${c.REVENUE_WIDGET}`);
              portalHub.unsubscribe(`${entity.entityid}_${c.CALL_CENTER_CAPTURE_UPLOADED}`);
              portalHub.unsubscribe(`${entity.entityid}_${c.OCCUPANCY_DEMAND_CHANGE}`);
              portalHub.unsubscribe(`${entity.entityid}_GPIO`);
              portalHub.unsubscribe(`${entity.entityid}_ACTIVITY_CHANGE`);
              portalHub.unsubscribe(`${entity.entityid}_${c.REVENUE_DASHBOARD_DATA_CHANGE}`);
            }
          })
      }
    }

    };

  }, [portalHub.isOpen, allEntities]);

  useEffect(() => {
    //user watcher (for state.user)
    //only call this if hub or ID changed
    if (currentUserId) {
      loadCurrentUserDetails();
      dispatch(fetchCashierShift(currentUserId));
    }
  }, [currentUserId]);

  useEffect(() => {
    //watch for auth changess
    if (authData.authStatus == "AUTHENTICATED") {
      if (
        authData.currentFacility?.facilityID != null &&
        authData.currentFacility.facilityID != facilityID
      ) {
        //only load if its new facility
        console.log(
          "AppStoreProvider: building context for ",
          authData.currentFacility?.facilityID
        );
        dispatch(loadEntities(authData.currentFacility.facilityID));

        //add to core
        //new core -- TODO REMOVE LATER  (this is part of refactor test)
        //leaving here commented so i can refer to it later (JG)
        //dispatch(loadEntityList(authData.currentFacility.facilityID));
      }
    } else {
      //wipe away redus store, they're not logged in.
      console.log("WIPE AWAY REDUX");
      dispatch(endCashierShift(null));
    }
  }, [authData.currentFacility]);

  useEffect(() => {
    if (authData.authStatus == "AUTHENTICATED" && facilityID) {
      console.log("initializing queue");
      //load the call center queue
      initializeQueue(currentUserId);
    }
  }, [authData.authStatus, facilityID]);

  useEffect(() => {
    if (authData.authStatus == "AUTHENTICATED" && facilityID) {
      console.log("setting facility context: ", facilityID);
      setCurrentFacility({
        facilityID: facilityID,
        name: facilityName,
      });
    }
  }, [authData.authStatus, facilityID]);

  useEffect(() => {
    if (currentUserId && scopeAwareFacilityID && scopeAwareFacilityID != null) {
      dispatch(
        getPermissionsAtEntity({
          entityID: scopeAwareFacilityID,
          userID: currentUserId,
          currentContextID: facilityID
        })
      );
    }
  }, [scopeAwareFacilityID, currentUserId]);

  useEffect(() => {
    if (contextID) {
      console.log("fetching feature flags");
      dispatch(fetchFlags(contextID));
      console.log("fetching sibling entities");
      dispatch(loadScope({ id: contextID, name: facilityName }));
    }
  }, [contextID]);

  useEffect(() => {
    if (scopeAwareFacilityID) {
      console.log("fetching roaming CCTerminals");
      dispatch(fetchRoamingCCTerminals(scopeAwareFacilityID));
      console.log("fetching offers");
      dispatch(fetchOffers(scopeAwareFacilityID));
    }
  }, [scopeAwareFacilityID]);

  useEffect(() => {
    if (!PortalHubConnected) return;
    portalHub.subscribe("BROADCAST", (msg) => {
      console.log("BROADCAST MSG: ", msg);
      if (msg.method === "SETTING_CHANGED") {
        dispatch(
          setSetting({
            entityid: msg.target,
            settingName: msg.payload.settingName,
            settingValue: msg.payload.settingValue,
          })
        );
      }
      if (msg.method === "BULK_SETTINGS_CHANGED") {
        dispatch(setSettings(msg.payload));
      }
    });
    return () => portalHub.unsubscribe("BROADCAST");
  }, [PortalHubConnected]);

  useEffect(() => {
    if (!PortalHubConnected) return;
    portalHub.subscribe("FEATURE_CHANGED", (msg) => {
      var message = JSON.parse(msg);
      if (message.EntityID == contextID) {
        dispatch(clearFlags());
        dispatch(fetchFlags(contextID));
      }
    });
    return () => portalHub.unsubscribe("FEATURE_CHANGED");
  }, [PortalHubConnected, contextID]);

  return (
    <>
      {authData.authStatus != "AUTHENTICATED" || loadedPermissions != null ? ( //if not authenticated
        <AppActionProvider>{children}</AppActionProvider> //let them through
      ) : (
        //otherwise, show preparing
        <Box
          height="75vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="3rem" />
          <Typography variant="h5">Preparing</Typography>
        </Box>
      )}
    </>
  );
};

export default AppStoreProvider;
