import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Zoom,
  Menu,
  ListItemIcon,
  ListItemText,
  Button,
  Divider,
  Typography,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

import { useTheme } from "@material-ui/core";
import { useStyles } from "./QueueDetailActions.style";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faHistory,
  faRetweetAlt,
  faTirePressureWarning,
  faLevelDownAlt,
} from "@fortawesome/pro-duotone-svg-icons";
import { faBolt, faTicketAlt } from "@fortawesome/pro-light-svg-icons";
import { faOctagonCheck } from "@fortawesome/pro-regular-svg-icons";

import _ from "lodash";
import moment from "moment";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHubContext from "../../../hooks/useHubContext";
import { FindEntity, FindNearestFacilityFromEntity } from "../../../state/slices/entities";

import * as c from "../../../constants";
import apiClient from "../../../auth/apiClient";
import CallCenterService from "../../../services/CallCenterService";
import TransactionService from "../../../services/TransactionManagerService";
import ValidationService from "../../../services/ValidationAccountService";
import TicketService from "../../../services/TicketService";
import ContractService from "../../../services/ContractService";
import CredentialService from "../../../services/CredentialService";

import NegotiateTicketForm from "../../Forms/NegotiateTicket";
import ApplyValidationForm from "../../Forms/ApplyValidation";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import settingsService from "../../../services/SettingsService";
import CycleDoorButton from "../../Entities/Modules/CycleDoorButton";
import useCallCenterTransaction from "../Hooks/useCallCenterTransaction";
import { useGate } from "../../../hooks/useGate";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useFlags } from "launchdarkly-react-client-sdk";

const validationService = new ValidationService(apiClient);
const callCenterService = new CallCenterService(apiClient);
const transactionService = new TransactionService(apiClient);
const emptyGuid = "00000000-0000-0000-0000-000000000000";
const settingsSrv = new settingsService(apiClient);
const ticketService = new TicketService(apiClient);
const contractService = new ContractService(apiClient);
const credentialService = new CredentialService(apiClient);

export const QueueDetailActions = ({ callID, ...props }) => {
  const {contractNest} = useFlags();
  const enqueueSnackbar = useEnqueueSnackbar();
  const classes = useStyles();

  const { hasPermissions } = useHasPermissions();
  const ApplyValidationsPermission = hasPermissions([
    "callcenter.applyvalidations",
  ]);
  const OpenGatesPermission = hasPermissions(["callcenter.opengates"]);
  const NegotiateTicketPermission = hasPermissions([
    "callcenter.modifyEntryTime",
  ]);
  const isContractNest = useFeatureFlag("Contract Nest");
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const queueItem =
    useSelector(
      (state) => _.find(state.callcenter.Queue, { callID }),
      shallowEqual
    ) ?? {};
  const deviceMode = useSelector(
    (state) =>
      _.find(
        FindEntity(state.entities?.EntityList ?? [], queueItem.deviceID)
          ?.settings ?? [],
        { name: "devicemode" }
      )?.value
  );
  const currentUser = useCurrentUser();

  const facilityID =
    useSelector((state) =>
      FindNearestFacilityFromEntity(state.entities?.EntityList ?? [], queueItem.deviceID)
    )?.entityid ?? "";

  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);

  const deviceName =
    useSelector(
      (state) =>
        FindEntity(state.entities?.EntityList ?? [], queueItem.deviceID)?.name
    ) ?? "Device";
  const [allDisabled, setAllDisabled] = React.useState(false);
  const [validationAccountIDs, setValidationAccountIDs] = useState();
  const callCenterTransaction = useCallCenterTransaction({entityID: queueItem.deviceID});
  const { 
    isGateOpen,
    getGateActionAsEnum,
    getGateActionAsString,
    sendGateAction 
  } = useGate(queueItem.deviceID, true);

  const isResponding = useSelector(
    (state) =>
      FindEntity(state.entities.EntityList, queueItem.deviceID)?.state !=
        null ?? false,
    shallowEqual
  );

  const [hasValidationOffers, setHasValidationOffers] = useState(false);
  const [validationOffers, setValidationOffers] = useState();

  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const toggleValidationModal = () => {
    handleCloseActionMenu();
    setValidationModalOpen(!validationModalOpen);
  };

  const [isEntryTimeModalOpen, setEntryTimeModalOpen] = useState(false);
  const toggleEntryTimeModal = () => {
    handleCloseActionMenu();
    setEntryTimeModalOpen(!isEntryTimeModalOpen);
  };

  useEffect(() => {
    //if portalhub isn't connected, disable everything
    setAllDisabled(!PortalHubConnected);
    return () => {};
  }, [PortalHubConnected]);

  useEffect(() => {
    //if callID is different
    console.log("queueItem", queueItem);
    shouldDisableValidations();
    return () => {};
  }, [queueItem.callID, callCenterTransaction?.LostTicket]);

  const [isDisabledBecauseLostTicket, setIsDisabledBecauseLostTicket] = useState(false)
  const shouldDisableValidations = async () => {
    let disable = false;
    if (callCenterTransaction?.LostTicket) {
      try {
        const res = await settingsSrv.getSettingByName(facilityID, "setting.lostticketallowvalidations");
        if (res.data["setting.lostticketallowvalidations"] === undefined
          || res.data["setting.lostticketallowvalidations"]?.toLowerCase() !== "true") {
          disable = true;
        }
      } catch (err) {
        console.log(err);
        disable = false;
      }
    }
    setIsDisabledBecauseLostTicket(disable);
  };

  //the actions menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };

  function capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }  

  const handleGateAction = useCallback(async (mode) => {
    handleCloseActionMenu();
    const action = getGateActionAsEnum(mode);
    const actionStr = getGateActionAsString(mode, false);
    const clientActionStr = getGateActionAsString(mode, true);
    // TODO: should we send this up as UTC 
    const timeOfAction = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    try {
      await sendGateAction(actionStr, timeOfAction);
      await callCenterService.createAction(callID, {
        type: action,
        callCenterUserID: currentUser.UserID,
        actionTime: timeOfAction,
      });
      enqueueSnackbar(
        capitalizeString(clientActionStr) + " Gate command sent to device.",
        { variant: "info", TransitionComponent: Zoom }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Unexpected issue commanding gate to " + clientActionStr + ". Please try again.",
        {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "ErrorCommandingGate",
        }
      );
    }
  }, [getGateActionAsEnum, getGateActionAsString, sendGateAction]);

  const handlePrintTicket = async () => {
    handleCloseActionMenu();
    try {
      portalHub.invoke("CallCenter", {
        action: "GENERATE_TICKET",
        callDetails: {
          deviceID: queueItem.deviceID,
        },
      });

      await callCenterService.createAction(queueItem.callID, {
        type: c.CALLCENTER_ACTIONS.TICKET_GENERATED,
        callCenterUserID: currentUser.UserID,
        actionTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
      });

      enqueueSnackbar("Print Ticket command sent to device.", {
        variant: "info",
        TransitionComponent: Zoom,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unexpected issue generating ticket. Please try again.", {
        variant: "error",
        TransitionComponent: Zoom,
        tag: "ErrorGeneratingTicket",
      });
    } finally {
      //do something here?
    }
  };

  const getValidationAccounts = useCallback(async () => {
    try {
      const response = await validationService.getValidationAccountsForUser(
        scopeAwareFacilityID,
        currentUser.UserID,
        true
      );
      setValidationAccountIDs(
        response.data.map((account) => account.contractHolderID)
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Could not find associated validation accounts", {
        variant: "error",
        tag: "FailedToFindValidationAccounts",
      });
    }
  }, [currentUser.UserID, scopeAwareFacilityID]);

  useEffect(() => {
    if (scopeAwareFacilityID) getValidationAccounts();
  }, [currentUser.UserID, scopeAwareFacilityID, getValidationAccounts]);


  const getValidationOffers = useCallback(async () => {
    try {
      if (facilityID === scopeAwareFacilityID || !callCenterTransaction?.TicketID) {
        const response = await validationService.getValidationOffersForGivenValidationAccounts(
          facilityID,
          validationAccountIDs
        );

        if (response.data &&  response.data.some(x => x.offers.length > 0)) {
          setHasValidationOffers(true);
          setValidationOffers(response.data);
        } else {
          setHasValidationOffers(false);
          setValidationOffers();
        }
    } else {
      // get ticket and filter offers
      const ticketResponse = await ticketService.getTicket(callCenterTransaction?.TicketID, facilityID);
      const ticket = ticketResponse.data;

      const response = await validationService.getValidationOffersForGivenValidationAccounts(
        facilityID,
        validationAccountIDs,
        ticket.facilityID
      );

      if (response.data &&  response.data.some(x => x.offers.length > 0)) {
        setHasValidationOffers(true);
        setValidationOffers(response.data);
      } else {
        setHasValidationOffers(false);
        setValidationOffers();
      }
    }    
    } catch (err) {
      console.error(err);
      setHasValidationOffers(false);
    }
  }, [facilityID, callCenterTransaction?.TicketID, scopeAwareFacilityID, JSON.stringify(validationAccountIDs)]);

  useEffect(() => {
    if (facilityID && validationAccountIDs) getValidationOffers();
  }, [facilityID, callCenterTransaction?.TicketID, scopeAwareFacilityID, JSON.stringify(validationAccountIDs), getValidationOffers]);

  const handleValidationSubmit = async (validation) => {
    try {
      await callCenterService.createAction(queueItem.callID, {
        type: c.CALLCENTER_ACTIONS.ISSUE_VALIDATION,
        callCenterUserID: currentUser.UserID,
        actionTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
      });

      portalHub.invoke("CallCenter", {
        action: "ISSUE_VALIDATION",
        callDetails: {
          deviceID: queueItem.deviceID,
          payload: {
            validationsInventoryID: validation.id,
            validationTypeID: validation.type,
            validationKindID: validation.kind,
            validationFormatID: validation.format,
            rateBlobID: validation.rateBlobID,
            validationAmount: validation.amount,
            restrictions: validation.restrictions,
            validationOfferName: validation.name,
          },
        },
      });

      enqueueSnackbar(`Apply Validation request sent to ${deviceName}`, {
        variant: "info",
        TransitionComponent: Zoom,
      });
      setValidationModalOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "There was an issue applying the validation. Please try again.",
        {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "FailedToEApplyValidation",
        }
      );
    }
  };

  const handleNestOverride = async () => {
    handleCloseActionMenu();
    const credential = await getAccessHolder();
    if (!credential) return;
    await createCallCenterAction(c.CALLCENTER_ACTIONS.OVERRIDE_NESTVIOLATION);
    try{
        await contractService.updateAccessHolderNestOverride(credential.accessHolderID, true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to override the nest violation.", {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "ErrorOverridingNestViolation",
        }
      );
      return;
    }
    try {
      portalHub.invoke("CallCenter", {
        action: "OVERRIDE_NESTVIOLATION",
        callDetails: {
          deviceID: queueItem.deviceID,
          callCenterUserID: currentUser.UserID,
          payload: credential,
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Failed to send override nest violation to ${deviceName}`,
        {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "FailedtoOverrideNestViolation",
        }
      );
      return;
    } finally {
      enqueueSnackbar(`Nest violation overridden`, {
        variant: "info",
        TransitionComponent: Zoom,
      });
    }
  };

  const checkInvalidModes = (modes) => {
    var isValid = true;
    modes.forEach((value) => {
      if (deviceMode?.toUpperCase() === value.toUpperCase()) {
        isValid = false;
        return;
      }
    });
    return isValid;
  };

  const checkValidModes = (modes) => {
    var isValid = false;
    modes.forEach((value) => {
      if (deviceMode?.toUpperCase() === value.toUpperCase()) {
        isValid = true;
        return;
      }
    });
    return isValid;
  };

  const getAccessHolder = async () => {
    var credential;
    try {
      const accessHolderResponse = await contractService.getAccessHolder(
        facilityID,
        callCenterTransaction.AccessHolderID
      );
      const response = accessHolderResponse.data;
      const credentialResponse = await credentialService.GetAccessCredentialsForAccessHolder(
        callCenterTransaction.AccessHolderID,
        response.contractID
      );
      credential = credentialResponse.data.collection.filter(cred => cred.credentialReference === callCenterTransaction?.CredentialReference)[0];
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to retrieve access holder info.", {
        variant: "error",
        TransitionComponent: Zoom,
        tag: "FailedToRetrieveAccessholderInfo",
        }
      );
    }
    return credential;
  }
  
  const createCallCenterAction = async (type) => {
    try {
      await callCenterService.createAction(callID, {
        type: type,
        callCenterUserID: currentUser.UserID,
        actionTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to create call center action.", {
          variant: "error",
          TransitionComponent: Zoom,
          tag: "FailedToCreateCallCenterAction",
        }
      );
    }
  }
  
  //the options - end

  return (
    <>
      <Button
        onClick={handleOpenActionMenu}
        className={clsx(["actions-button"])}
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<FontAwesomeIcon icon={faBolt} />}
        style={{
          marginRight: 20,
          lineHeight: 2,
          marginTop: 2,
          marginBottom: 4,
        }}
      >
        Actions
      </Button>
      <Menu
        className={clsx(["actions-menu"])}
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseActionMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={Fade}
      >
        <Typography color="primary" style={{ padding: 6, paddingLeft: 14 }}>
          Actions
        </Typography>
        <Divider />
        {checkInvalidModes([c.DEVICE_MODE.EXIT, c.DEVICE_MODE.KIOSK, c.DEVICE_MODE.DOOR]) && (
          <MenuItem
            className={clsx(["action-print-ticket", classes.actionPrintTicket])}
            disabled={allDisabled || !isResponding}
            onClick={handlePrintTicket}
          >
            <ListItemIcon style={{ marginRight: -15 }}>
              <FontAwesomeIcon icon={faPrint} />
            </ListItemIcon>
            <ListItemText primary="Print Ticket" />
          </MenuItem>
        )}
        {NegotiateTicketPermission && checkInvalidModes([c.DEVICE_MODE.DOOR]) && (
          <MenuItem
            className={clsx([
              "action-modify-entry",
              classes.actionModifyEntryTime,
            ])}
            onClick={toggleEntryTimeModal}
            disabled={
              (!_.isUndefined(callCenterTransaction) &&
                callCenterTransaction !== null &&
                callCenterTransaction.CredentialReference !== null &&
                callCenterTransaction.CredentialReference !== emptyGuid) ||
              (!_.isUndefined(callCenterTransaction) &&
                callCenterTransaction !== null &&
                callCenterTransaction.CredentialID !== null) ||
              !isResponding ||
              allDisabled
            }
          >
            <ListItemIcon style={{ marginRight: -15 }}>
              <FontAwesomeIcon icon={faHistory} />
            </ListItemIcon>
            <ListItemText primary="Modify Entry Time" />
          </MenuItem>
        )}
        {ApplyValidationsPermission && checkInvalidModes([c.DEVICE_MODE.DOOR]) && (
          <Tooltip
            className={clsx("action-validate-tooltip")}
            title={isDisabledBecauseLostTicket == true
              ? "Not allowed on lost ticket"
              : hasValidationOffers == false ? "No validations available for this ticket" :
               ""}
          >
            <div>
              <MenuItem
                className={clsx(["action-validate", classes.actionValidate])}
                data-testid="action-validate"
                onClick={toggleValidationModal}
                disabled={
                  isDisabledBecauseLostTicket == true ||
                  _.isUndefined(callCenterTransaction?.TicketID) ||
                  callCenterTransaction?.TicketID == emptyGuid ||
                  allDisabled ||
                  !isResponding ||
                  hasValidationOffers == false
                }
              >
                <ListItemIcon style={{ marginRight: -15 }}>
                  <FontAwesomeIcon icon={faTicketAlt} />
                </ListItemIcon>
                <ListItemText primary="Validate" />
              </MenuItem>
            </div>
          </Tooltip>
        )}
        { contractNest && isContractNest && (
          <MenuItem
          className={clsx(["action-nest-override", classes.actionNestOverride])}
          data-testid="action-nest-override"
          onClick={handleNestOverride}
          disabled={callCenterTransaction?.ResultCode != 21}
        >
          <ListItemIcon style={{ marginRight: -15 }}>
            <FontAwesomeIcon icon={faOctagonCheck} />
          </ListItemIcon>
          <ListItemText primary="Nest Override" />
        </MenuItem>          
        )}
        {OpenGatesPermission && checkInvalidModes([c.DEVICE_MODE.KIOSK, c.DEVICE_MODE.DOOR]) && (
          <MenuItem
            className={clsx(["action-vend-gate", classes.actionVendGate])}
            onClick={() => {
              handleGateAction("vend");
            }}
            disabled={allDisabled || !isResponding}
          >
            <ListItemIcon style={{ marginRight: -15 }}>
              <FontAwesomeIcon
                icon={faLevelDownAlt}
                rotation={isGateOpen() ? 0 : 180}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${!isGateOpen() ? "Lock Open" : "Close"} Gate`}
            />
          </MenuItem>
        )}
        {OpenGatesPermission && checkInvalidModes([c.DEVICE_MODE.KIOSK, c.DEVICE_MODE.DOOR]) && (
          <MenuItem
            className={clsx(["action-pulse-gate", classes.actionPulseGate])}
            disabled={allDisabled || !isResponding}
            onClick={() => {
              handleGateAction("pulse");
            }}
          >
            <ListItemIcon style={{ marginRight: -15 }}>
              <FontAwesomeIcon icon={faRetweetAlt} swapOpacity />
            </ListItemIcon>
            <ListItemText primary="Cycle Gate" />
          </MenuItem>
        )}
        {checkValidModes([c.DEVICE_MODE.DOOR]) && (
          <CycleDoorButton
            data-id="cycle-door-button"
            disabled={allDisabled || !isResponding}
            entityID={queueItem.deviceID} 
            onClick={handleCloseActionMenu}/>
        )}
        {deviceName === "Callibrity Terminal" && ( // (-_-)
          <MenuItem
            className={clsx([
              "action-puncture-tire",
              classes.actionPunctureTires,
            ])}
            disabled={allDisabled || !isResponding}
          >
            <ListItemIcon style={{ marginRight: -15 }}>
              <FontAwesomeIcon icon={faTirePressureWarning} />
            </ListItemIcon>
            <ListItemText primary="Puncture Tires" />
          </MenuItem>
        )}
      </Menu>
      <NegotiateTicketModal
        onClose={toggleEntryTimeModal}
        open={isEntryTimeModalOpen}
        deviceID={queueItem.deviceID}
        deviceName={deviceName}
        callID={queueItem.callID}
        onSubmit={toggleEntryTimeModal}
      />
      <Dialog open={validationModalOpen} onClose={toggleValidationModal}>
        <DialogContent>
          <ApplyValidationForm
            onSubmit={handleValidationSubmit}
            contractHolderIDs={validationAccountIDs}
            ticketID={callCenterTransaction?.TicketID}
            deviceID={queueItem.deviceID}
            validationOffers={validationOffers}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
QueueDetailActions.defaultProps = {};
QueueDetailActions.propTypes = {};
export default QueueDetailActions;

const NegotiateTicketModal = ({
  open,
  onClose,
  onSubmit,
  deviceID,
  deviceName,
  callID,
}) => {
  const { portalHub } = useHubContext();
  const enqueueSnackbar = useEnqueueSnackbar();
  const currentUser = useSelector((state) => state.user);
  const theme = useTheme();

  const handleSubmit = async (ticket) => {
    try {
      const response = await transactionService.createTransientParkingTransaction(
        {
          deviceID,
          credentialID: ticket.credentialID,
          activityDate: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
          activityType: c.ACTIVITY_TYPES.Enter,
          reason: "Negotiated Ticket",
          callCenterSupplied: true,
        }
      );
      if (!response || response.status !== 200) {
        throw "Invalid response";
      }
      await callCenterService.createAction(callID, {
        type: c.CALLCENTER_ACTIONS.NEGOTIATE_TICKET,
        callCenterUserID: currentUser?.UserID,
        actionTime: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ssZ"),
      });

      ticket.parkingTransactionID = response.data.parkingTransactionID;

      portalHub.invoke("CallCenter", {
        action: "NEGOTIATE_TICKET",
        callDetails: {
          deviceID,
          payload: ticket,
        },
      });

      enqueueSnackbar(`Negotiated ticket request sent to ${deviceName}`, {
        variant: "info",
        TransitionComponent: Zoom,
      });

      onSubmit();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Failed to send negotiated ticket to ${deviceName}`, {
        variant: "error",
        TransitionComponent: Zoom,
        tag: "FailedToSendNegotiatedTicket",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={clsx("negotiated-ticket-dialog")}
    >
      <DialogTitle
        style={{ color: theme.palette.primary.main }}
        id="negotiated-ticket-dialog"
      >
        Modify Entry Time of Ticket
      </DialogTitle>
      <DialogContent>
        <NegotiateTicketForm
          entityID={deviceID}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};
