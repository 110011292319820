import React, {useEffect, useContext} from "react";
import { useSelector } from "react-redux";

import FacilityGroupDisplay from "./FacilityGroupDisplay/FacilityGroupDisplay.index";
import FacilityDisplay from "./FacilityDisplay/FacilityDisplay.index";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRevenueDataContext } from "./RevenueDataProvider";

export default function RevenueDashboardWidget() {

  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);
  const { facilityGroups } = useFlags();
  const facilities = useSelector((state) => state.entityScope.selected) ?? [];

  const {
    loadRevenueData
  } = useRevenueDataContext();

  useEffect(() => {
    loadRevenueData(facilities);
  }, [facilities]);

  return ((facilityGroups && facilityGroupID) ?
       (
        <FacilityGroupDisplay />
      ) : (
        <FacilityDisplay />
      )
  );
}