import React, { useState, useEffect } from "react";

/* Form */
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import * as Yup from "yup";

/* Material UI */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
  Tooltip,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* Style */
import { useStyles } from "../styles";

/* Hooks */
import { useSettingDispatchChange } from "../index";

/* Utilities */
import { ENTITY_TYPE } from "../../../../constants";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

/* State */
import { useSelector, shallowEqual } from "react-redux";
import { FindEntity } from "../../../../state/slices/entities";


export const valetAreaTicketSettingsValidationSchema = Yup.object().shape({
  valetareaticketlength: Yup.number()
    //original - value entered in textbox, transformed - value post Yup.Number conversion
    .transform((transformed, original) => original == "" ? undefined : transformed)
    .required("Required")
    .typeError("Value must be a number")
    .integer("Value must be a whole number")
    .min(1, "Value must be from 1 to 27")
    .max(27, "Value must be from 1 to 27")
});

const ValetAreaTicketSettingsForm = ({
  entityID,
  settingValues,
  onGuestTypesClicked,
}) => {
  const classes = useStyles();
  const [valetAreaTicketSettingsExpanded, setValetAreaTicketSettingsExpanded] = useState(true);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { dispatchSetting } = useSettingDispatchChange();

  const toggleValetAreaTicketSettingsExpanded = () => {
    setValetAreaTicketSettingsExpanded(!valetAreaTicketSettingsExpanded);
  };

  const parentEntity = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return FindEntity(state.entities?.EntityList ?? [], entity.parententityid);
  }, shallowEqual);

  const shouldSettingsBeDisabled = parentEntity.typeid === ENTITY_TYPE.FacilityGroup ? true : false;

  const {
    control,
    setValue,
    formState: { errors },
    trigger
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(valetAreaTicketSettingsValidationSchema),
    defaultValues: {
      valetareaticketlength: settingValues.valetareaticketlength,
    },
  });

  const handleTicketLengthChanged = async (e, errors) => {
    let targetName = e.target.name;
    let targetValue = e.target.value;

    if (targetValue === settingValues[targetName])
      return;

    if (errors[targetName]) {
      setValue(targetName, settingValues[targetName], {
        shouldValidate: true,
      }); 
      return;
    }

    setValue(targetName, targetValue);
    if (!(await dispatchSetting(entityID, targetName, targetValue))) {
      setValue(targetName, settingValues[targetName]);
      enqueueSnackbar("Unable to change Valet Ticket Length", {
        variant: "error",
        tag: "valetticketlength",
      });
    }
  };

  //To trigger validation on form load
  useEffect(() => {
    if (!shouldSettingsBeDisabled) {
      trigger();
    }
  }, []);

  return (
    <Grid
      container
      className={classes.panelRoot}
      data-testid="valetarea-ticket-panel"
    >
      <Accordion
        data-tag="valetAreaTicketSettings"
        expanded={valetAreaTicketSettingsExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleValetAreaTicketSettingsExpanded}
        >
          <Typography className={classes.heading}>
            Valet Ticket Settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid data-tag="valetareaticketlength" item xs={12} sm={6} md={4}>
              <Controller
                name="valetareaticketlength"
                control={control}
                render={({ field }) => (
                  <Tooltip placement="top-end"
                    title="Length of preprinted or generated valet ticket"
                  >
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      id="valetareaticketlength"
                      label="Valet Ticket Length"
                      type="number"
                      disabled={shouldSettingsBeDisabled}
                      onBlur={e => handleTicketLengthChanged(e, errors)}
                      error={!!errors.valetareaticketlength}
                      helperText={errors.valetareaticketlength && errors.valetareaticketlength.message}
                      FormHelperTextProps={{
                        'data-testid': `${field.name}-helper-text`
                      }}
                    />
                  </Tooltip>
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}  style={{ marginBottom: '10px', marginLeft: '15px' }}>
          <Button
            className={clsx(["GuestType"])}
            variant="contained"
            fullWidth
            onClick={onGuestTypesClicked}
            color="primary"
          >
            Guest Types
          </Button>
        </Grid>
      </Accordion>
    </Grid>
  );
};

ValetAreaTicketSettingsForm.defaultProps = {
  settingValues: {},
};

export default ValetAreaTicketSettingsForm;