import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"; //yknow, like orange slices
import apiClient from "../../auth/apiClient"; //the world doesnt spin without rest API cals
import * as c from "../../constants/";
import _ from "lodash";
import FacilityService from "../../services/FacilityService";
import ValetAreaService from "../../services/ValetAreaService";
const facilityService = new FacilityService(apiClient);
const valetareaService = new ValetAreaService(apiClient);

export const changeFacilities = createAsyncThunk(
  "facilities/change",
  async ({ userID, facilityID }, thunkAPI) => {
    const state = thunkAPI.getState();
    // if it's already in state, don't do the thing
    if (!FindEntity(state.entities.EntityList, facilityID)) {
      await thunkAPI.dispatch(addAtNode({ entityID: facilityID }));
      await thunkAPI.dispatch(
        getPermissionsAtEntity({
          userID,
          entityID: facilityID,
          currentContextID: facilityID
        })
      );
    }
    await thunkAPI.dispatch(setContext(facilityID));
  }
);

export const addAtNode = createAsyncThunk(
  "entities/add",
  async ({ entityID }, thunkAPI) => {
    const nodes = await apiClient.get(`entities/${entityID}/tree`);
    // since this endpoint returns the entire tree from top to bottom, just find the node we originally cared about and its children
    const built = BuildTree(nodes.data);
    const foundNode = FindEntity(built, entityID);
    return { node: foundNode, tree: built };
  }
);

export const getPermissionsAtEntity = createAsyncThunk(
  `entities/permissions`,
  async ({ userID, entityID, currentContextID }, thunkAPI) => {
    if (_.isNil(currentContextID)) {
      // maintain backwards compatability, this variable is not needed for non-FG contexts
      currentContextID = entityID;
    }
    const entityPermissions = await apiClient.get(
      `accounts/v2/permissions/${userID}?entityID=${entityID}`
    );

    const entityData = await apiClient.get(`entities/${currentContextID}/rich`);
    entityData.data.permissions =
      [
        ...entityPermissions.data[0].permissions?.map(
          (permission) => permission.permissionName
        ),
        ...entityPermissions.data[0].groups
          ?.map((group) => [...group.permissions])
          .flat(),
      ] ?? [];

    return entityData.data;
  }
);

export const entitySettingsUpdate = createAsyncThunk(
  "entities/v2/entitySettingsUpdateBulk",
  async (bulkSettings, thunkAPI) => {
    if (bulkSettings[0].settingName === "setting.devicedisabled") {
      bulkSettings.push({
        userName: bulkSettings[0].userName,
        entityId: bulkSettings[0].entityId,
        settingName: "setting.laneclosedsign",
        settingValue: bulkSettings[0].settingValue,
      });
    }
    const response = await apiClient.put("v2/settings/bulk", bulkSettings, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  }
);

export const entitySettingsDelete = createAsyncThunk(
  "entities/entitySettingsDelete",
  async ({ entityId, settingName, settingValue }, thunkAPI) => {
    const response = await apiClient.delete(
      `v2/settings/${entityId}/${settingName}`
    );
    return response.data;
  }
);

export const entityUpdate = createAsyncThunk(
  "/entities/entityUpdateGeneric",
  async (payload, thunkAPI) => {
    const response = await apiClient.put(payload.path, payload.entity, {
      headers: { "Content-Type": "application/json" },
    });

    const richEntity = await apiClient.get(
      "entities/" +
        (response.data?.deviceID ??
          response.data?.facilityID ??
          response.data?.valetAreaID ??
          response.data?.areaID ??
          response.data) +
        "/rich/"
    );
    return richEntity.data;
  }
);

export const onboardFacility = createAsyncThunk(
  "/facility/onboard",
  async (payload, thunkAPI) => {
    const result = await facilityService.onboardFacility(
      payload.parentEntityID,
      payload
    );
    return result.data;
  }
);

export const onboardValetArea = createAsyncThunk(
  "/valetarea/onboard",
  async (payload, thunkAPI) => {
    const result = await valetareaService.onboardValetArea(payload);
    return result.data;
  }
);

export const entityCreate = createAsyncThunk(
  "/entities/entityCreateGeneric",
  async (payload, thunkAPI) => {
    const response = await apiClient.post(payload.path, payload.entity, {
      headers: { "Content-Type": "application/json" },
    });
    const richEntity = await apiClient.get(
      "entities/" +
        (response.data?.deviceID ??
          response.data?.facilityID ??
          response.data?.areaID ??
          response.data) +
        "/rich/"
    );
    return richEntity.data;
  }
);
export const entityDelete = createAsyncThunk(
  "/entities/entityDeleteGeneric",
  async (payload, thunkAPI) => {
    const response = await apiClient.patch(
      payload.path,
      [payload.payload.entityid],
      { headers: { "Content-Type": "application/json" } }
    );
    return payload;
  }
);

/**
 * Used to retrieve all entities at a given level
 */
export const fillEntityLevel = createAsyncThunk(
  "entities/fill",
  async ({ entityID, userID, currentContextID }, thunkAPI) => {
    if (_.isNil(currentContextID)) {
      // maintain backwards compatability, this variable is not needed for non-FG contexts
      currentContextID = entityID;
    }
    // fetch all entities under the given context
    const nodes = await apiClient.get(`entities/${currentContextID}/tree`);
    const built = BuildTree(nodes.data);
    const neededNodes = FindEntity(built, currentContextID);
    // get all permissions for the given context
    const entityPermissions = await apiClient.get(
      `accounts/v2/permissions/${userID}?entityID=${entityID}`
    );
    // grab permissions that need set/updated/maintained in state.EntityList
    let permissionDict = {};
    entityPermissions.data.forEach((grouping) => {
      permissionDict[grouping.entityID] = grouping.permissions.map(
        (permission) => permission.permissionName
      );
    });
    applyPermissions(permissionDict, neededNodes);
    const parentID =
      neededNodes.parententityid == null
        ? neededNodes.entityid
        : neededNodes.parententityid;
    return { children: neededNodes, parentID };
  }
);

function applyPermissions(permissionDict, node) {
  const permissions = permissionDict[node.entityid];
  if (permissions) node.permissions = permissions;
  if (node.children) {
    node.children.forEach((childNode) => {
      applyPermissions(permissionDict, childNode);
    });
  }
}

const initialState = {
  ContextID: null, //the current facilityID
  Context: null, //the current facility nested structure (rich data)

  EntityList: null, //nested list of rich data objects
  EntityIDs: null, //nested list of just id/parentid

  EntityListRaw: null, //flat list of rich data objects  (THIS WILL GO AWAY)
  EntityIDsRaw: null, //flat list of just id/parentid

  Loaded: false,
  Loading: false,
};

const slice = createSlice({
  name: "entities",
  // in case cart was saved items, will be here:
  initialState: initialState,
  // reducers actions
  reducers: {
    //
    addAtNode: (state, action) => {
      const { entity } = action.payload;
      const parent = FindEntity(state.EntityList, entity.parententityid);
      parent.children.push(entity);
    },
    add: (state, action) => {
      state.EntityList.push(action.payload);
    },
    remove: (state, action) => {
      //.payload == entityid
      //remove where entity meets action.payload
      return state.EntityList.filter(
        (entity) => entity.entityid !== action.payload
      );
    },
    load: (state, action) => {
      state.EntityListRaw = action.payload;
      state.EntityList = BuildTree(action.payload); //the entire list
    },
    loadIDs: (state, action) => {
      state.EntityIDsRaw = action.payload;
      state.EntityIDs = BuildTree(action.payload);
    },
    setContext: (state, action) => {
      state.ContextID = action.payload;
      state.Context = FindEntity(state.EntityList, action.payload);
    },
    setloading: (state, action) => {
      state.Loading = action.payload;
    },
    setloaded: (state, action) => {
      state.Loaded = action.payload;
    },
    setEntityProperty: (state, action) => {
      const { entityid, property, value } = action.payload;
      const foundEntity = FindEntity(state.EntityList, entityid);
      if (foundEntity) {
        foundEntity[property] = value;
        //for good measure, lets refresh context, in case our change affected it
        state.Context = FindEntity(state.EntityList, state.ContextID);
      }
    },
		setEntityStateIfOffline: (state, action) => {
			const { entityid, value } = action.payload;
			const foundEntity = FindEntity(state.EntityList, entityid);
			if (foundEntity && !foundEntity.state) {
				foundEntity.state = value;
				//for good measure, lets refresh context, in case our change affected it
				state.Context = FindEntity(state.EntityList, state.ContextID);
			}
		},
    setEntitiesProperty: (state, action) => {
      const {entities} = action.payload;
      entities.map(e => {
        const {entityid, property, value} = e;
        const foundEntity = FindEntity(state.EntityList, entityid);
        if (foundEntity) {
          foundEntity[property] = value;
        }
      });
      //for good measure, lets refresh context, in case our change affected it
      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    setSetting: (state, action) => {
      const { entityid, settingName, settingValue } = action.payload;
      const foundEntity = FindEntity(state.EntityList, entityid);
      if (!foundEntity) return;
      const setting = foundEntity.settings?.find(
        (x) => x.name.toLowerCase() === settingName.toLowerCase()
      );
      console.log("found setting: ", current(setting));
      if (!setting) return;
      setting.value = settingValue;
      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    setSettings: (state, action) => {
      action.payload.map((setting) => {
        const foundEntity = FindEntity(state.EntityList, setting.entityID);
        if (!foundEntity) return;
        const foundSetting = foundEntity.settings?.find(
          (x) =>
            x.name.toLowerCase() ===
            setting.settingName.toLowerCase().replace("setting.", "")
        );
        if (!foundSetting) return;
        foundSetting.value = setting.settingValue;
      });

      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    reset: () => initialState,
  },
  extraReducers: {
    [entityDelete.rejected]: (state, action) => {
      console.log("redux-entityDelete failed", action);
    },
    [entityDelete.fulfilled]: (state, action) => {
      const { entityid, parententityid } = action.payload.payload;

      console.log("DELETING", entityid);
      console.log("FROM", parententityid);

      var _parent = FindEntity(state.EntityList, parententityid); //removes it from its parent
      _parent.children = _parent.children.filter(
        (entity) => entity.entityid !== entityid
      );
      //state.EntityListRaw = state.EntityListRaw.filter(entity => entity.entityid !== entityid);
      var _parentIDs = FindEntity(state.EntityIDs, parententityid); //removes it from its parent
      _parentIDs.children = _parent.children.filter(
        (entity) => entity.entityid !== entityid
      );

      state.EntityIDsRaw = state.EntityIDsRaw.filter(
        (entity) => entity.entityid !== entityid
      );
      state.EntityListRaw = state.EntityIDsRaw.filter(
        (entity) => entity.entityid !== entityid
      );

      _parentIDs.children.map((child, i) => {
        console.log("child", child.name);
      });

      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    [entityUpdate.rejected]: (state, action) => {
      console.log("redux-entityUpdate failed", action);
    },
    [entityUpdate.fulfilled]: (state, action) => {
      //update redux store   (currently only updating devices)  -- we need a reducer that uses both, then goes specific (see toolkit)
      //THIS SHOULD BECOME JUST FINDING AND REPLACING ( BUT FAC/AREA/DEVICE NEED TO BE NORMALIZED TO A GENERIC ENTTIY OBJECT LIKE REDUX HAS)
      var targetedEntity = FindEntity(
        state.EntityList,
        action.payload.entityid
      );
      console.log("TARGET", targetedEntity);
      targetedEntity.name = action.payload.name;
      targetedEntity.details = action.payload.details;
      targetedEntity.settings = action.payload.settings;

      //update Context
      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    [onboardFacility.rejected]: (state, action) => {
      console.log("redux-onboardFacility failed", action);
    },
    [onboardFacility.fulfilled]: (state, action) => {
      console.log("redux-onboardFacility OK", action);
      let facilityMetaData = {
        entityid: action.payload.facilityID,
        parententityid: action.payload.parentEntityID,
        entitytype: c.ENTITY_TYPE.Facility,
        children: [],
        show: false, // what does this do?
      };

      let parentEntity = FindEntity(
        state.EntityList,
        action.payload.parentEntityID
      ); //the full entity list
      if (parentEntity.children === null) {
        parentEntity.children = [action.payload];
      } else {
        parentEntity.children.push(action.payload);
      }

      let rawParent = FindEntity(
        state.EntityIDs,
        action.payload.parentEntityID
      ); //the id-only tree
      if (rawParent.children === null) {
        rawParent.children = [facilityMetaData];
      } else {
        rawParent.children.push(facilityMetaData);
      }

      state.EntityIDsRaw.push(facilityMetaData);

      //update Context
      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    [onboardValetArea.rejected]: (state, action) => {
      console.log("redux-onboardValetArea failed", action);
    },
    [onboardValetArea.fulfilled]: (state, action) => {
      console.log("redux-onboardValetArea OK", action);
      let valetareaMetaData = {
        entityid: action.payload.valetareaID,
        parententityid: action.payload.parentEntityID,
        entitytype: c.ENTITY_TYPE.ValetArea,
        children: [],
        show: false, // what does this do?
      };

      let parentEntity = FindEntity(
        state.EntityList,
        action.payload.parentEntityID
      ); //the full entity list
      if (parentEntity.children === null) {
        parentEntity.children = [action.payload];
      } else {
        parentEntity.children.push(action.payload);
      }

      let rawParent = FindEntity(
        state.EntityIDs,
        action.payload.parentEntityID
      ); //the id-only tree
      if (rawParent.children === null) {
        rawParent.children = [valetareaMetaData];
      } else {
        rawParent.children.push(valetareaMetaData);
      }

      state.EntityIDsRaw.push(valetareaMetaData);

      //update Context
      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    [entityCreate.rejected]: (state, action) => {
      console.log("redux-entityCreate failed", action);
    },
    [entityCreate.fulfilled]: (state, action) => {
      console.log("redux-entityCreate OK", action); //update redux store   (currently only updating facility groups and devices)
      //WE NOW HAVE TO ADD IT TO THE TREE WHERE IT BELONGS
      let _idOnly = {
        entityid: action.payload.entityid,
        parententityid: action.payload.parententityid,
        entitytype: action.payload.typeid,
        children: action.payload.children,
        show: false,
      };

      let parentEntity = FindEntity(
        state.EntityList,
        action.payload.parententityid
      ); //the full entity list
      if (parentEntity.children === null) {
        parentEntity.children = [action.payload];
      } else {
        parentEntity.children.push(action.payload);
      }

      let rawParent = FindEntity(
        state.EntityIDs,
        action.payload.parententityid
      ); //the id-only tree
      if (rawParent.children === null) {
        rawParent.children = [_idOnly];
      } else {
        rawParent.children.push(_idOnly);
      }

      state.EntityListRaw.push(action.payload); //the raw list
      state.EntityIDsRaw.push(_idOnly);

      //update Context
      state.Context = FindEntity(state.EntityList, state.ContextID);
    },
    [entitySettingsUpdate.rejected]: (state, action) => {
      //couldnt update settings
      console.log(action, action.payload);
    },
    [entitySettingsUpdate.fulfilled]: (state, action) => {},
    [entitySettingsDelete.rejected]: (state, action) => {
      //couldnt update settings
      console.log(action, action.payload);
    },
    [entitySettingsDelete.fulfilled]: (state, action) => {
      //settings were updated
      var targetedEntity = FindEntity(
        state.EntityList,
        action.meta.arg.entityId
      );
      _.find(targetedEntity.settings, {
        name: action.meta.arg.settingName.replace("setting.", ""),
      }).value = action.meta.arg.settingValue.toString();
    },
    [getPermissionsAtEntity.rejected]: (state, action) => {
      console.log(action, action.payload);
    },
    [getPermissionsAtEntity.fulfilled]: (state, action) => {
      const { payload } = action;
      const parentEntity = FindEntity(state.EntityList, payload.parententityid);
      if (!parentEntity) {
        console.log(
          "Entity not found with parent id: ",
          payload.parententityid
        );
        return;
      }

      const foundEntity = _.find(parentEntity.children, {
        entityid: payload.entityid,
      });

      if (foundEntity) foundEntity.permissions = payload.permissions;
      else parentEntity.children.push(payload);
    },
    [addAtNode.rejected]: (state, action) => {
      console.log(action, action.payload);
    },
    [addAtNode.fulfilled]: (state, action) => {
      const { payload } = action;
      const parentNode = FindEntity(
        state.EntityList,
        payload.node.parententityid
      );

      if (!parentNode && payload.node.parententityid != null) {
        // hopping tenants
        // use the provided tree and add the whole slice to the top level tenant
        state.EntityList.children.push(...payload.tree.children);
      } else {
        if (!parentNode.children) parentNode.children = [payload.node];
        else parentNode.children.push(payload.node);
      }
    },
    [fillEntityLevel.rejected]: (state, action) => {
      console.log(action, action.payload);
    },
    [fillEntityLevel.fulfilled]: (state, action) => {
      const { payload } = action;

      // update the parent node in the EntityList
      const parentNode = FindEntity(state.EntityList, payload.parentID);

      if (!parentNode && payload.parentID != null) {
        // hopping tenants...
        // use the provided tree and add the whole slice
        state.EntityList.children.push(payload.children);
        return;
      } else if (!parentNode) {
        // stop traversal because we either are at the top, or this truly is an unknown node
        return;
      }

      // don't do a full wipe of the children in case we need to maintain device state, or any other state that is in process on an entity
      // instead, iterate through and only update what is absolutely necessary
      if (!parentNode.children) parentNode.children = [payload.children];
      else {
        // both trees start at the same parent, so we can go foward with the assumption that the starting positions should never differ
        (function merge(originalChildren, newChildren) {
          // first get any missing nodes at this level and concat them to the original collection
          const missing = newChildren.filter(
            (x) => !originalChildren.some((c) => c.entityid === x.entityid)
          );
          originalChildren.push(...missing);

          // now iterate through everything at this level and update the values from the given new list
          originalChildren.forEach((child) => {
            const foundExistingNode = newChildren.find(
              (x) => x.entityid === child.entityid
            );
            if (!foundExistingNode) return;
            // update only what's neccessary
            child.permissions = foundExistingNode.permissions;
            if (child.children || foundExistingNode.children)
              merge(child.children ?? [], foundExistingNode.children ?? []);
          });
        })(parentNode.children, [payload.children]);
      }
    },
  },
});

//actions
export const {
  settingsUpdateBulk,
  add,
  remove,
  load,
  loadIDs,
  setContext,
  setloading,
  setloaded,
  replaceEntity,
  setEntityProperty,
	setEntityStateIfOffline,
  setEntitiesProperty,
  setSetting,
  setSettings,
  reset,
} = slice.actions;
//reducer
export default slice.reducer;

export const changeContext = (entityID) => async (dispatch) => {
  dispatch(setContext(entityID));
};
export const loadEntity = (entityID) => async (dispatch) => {
  apiClient
    //.get('properties/getChildren/e3e2b062-258a-4705-971b-583cc3096cfe/')
    .get(`entities/${entityID}/rich`)
    .then((resp) => {
      //here we replace the entity whole sale
      //dispatch(load(resp.data));            //load the full entity tree into EntityList
    })
    .catch((err) => {
      console.error("Encountered error while loading entity:", err);
    })
    .finally(() => {
      //do nothing, getting IDs next
    });
};

//pass it in the facility ID you're interested in having as context
export const loadEntities = (entityID) => async (dispatch) => {
  dispatch(setloading(true));
  apiClient
    .get(`entities/${entityID}/tree`)
    .then((resp) => {
      dispatch(load(resp.data)); //load the full entity tree into EntityList
      dispatch(setContext(entityID));
    })
    .catch((err) => {
      console.error(
        "Encountered error while loading all entities from context:",
        err
      );
    })
    .finally(() => {
      //do nothing, getting IDs next
    });
  apiClient
    .get(`entities/${entityID}/tree/idsonly`)
    .then((resp) => {
      dispatch(loadIDs(resp.data));
      dispatch(setloaded(true));
    })
    .catch((err) => {
      console.error(
        "Encountered error while fetching ids from entity tree:",
        err
      );
    })
    .finally(() => {
      dispatch(setloading(false));
    });
};
//e,p,v   {e:entityId, p:propertyname, v: value}
export const modifyPropertyOnEntity = (_package) => async (dispatch) => {
  dispatch(setEntityProperty(_package));
};
/*
  given flat data structure that contains the entities
  returns it in a tree form, entities nested in the "children" array
*/
function BuildTree(data) {
  const idMapping = data.reduce((acc, el, i) => {
    acc[el.entityid] = i;
    return acc;
  }, {});
  let root;
  data.forEach((el) => {
    //find top parent, then walk recursively
    // Handle the root element
    if (el.parententityid === null) {
      root = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = data[idMapping[el.parententityid]];
    if (parentEl) {
      // Add our current el to its parent's `children` array
      parentEl.children = [...(parentEl.children || []), el];
    }
  });
  return root;
}

export function GetProperty(obj, targetid, propertyName) {
  return FindEntity(obj, targetid)[propertyName];
}

export function GetAllFacilities(entityList) {
  const facilities = [];
  (function walk(entityList) {
    if (!entityList) return;
    if (entityList.type?.toLowerCase() === "facility")
      facilities.push({
        entityid: entityList.entityid,
        parententityid: entityList.parententityid,
        name: entityList.name,
      });
    if (entityList.children) {
      for (let item of entityList.children) {
        walk(item);
      }
    }
  })(entityList);
  return facilities;
}

export function GetRawIDListWithScope(entityList) {
  const strippedTree = [];
  (function walk(entityList) {
    if (!entityList) return;
    strippedTree.push({
      entityid: entityList.entityid,
      parententityid: entityList.parententityid,
    });
    if (entityList.children)
      entityList.children.forEach((child) => walk(child));
  })(entityList);
  return strippedTree;
}

export function GetAllScopedPermissions(entityList) {
  let scopedPermissions = {};
  (function walk(entityList) {
    if (!entityList) return;
    if (entityList.permissions)
      scopedPermissions[entityList.entityid] = entityList.permissions;
    if (entityList.children) {
      for (let item of entityList.children) {
        walk(item);
      }
    }
  })(entityList);

  return scopedPermissions;
}

export function GetEntityTreeWithNoState(entityList) {
  if (!entityList) return {};
  const bareTree = (function walk(list, stripped = {}) {
    // first strip to what we need
    stripped.entityid = list.entityid;
    stripped.parententityid = list.parententityid;
    stripped.typeid = list.typeid;
    if (list.children) {
      stripped.children = [];
      list.children.forEach((child) => {
        stripped.children.push(walk(child));
      });
    }
    return stripped;
  })(entityList);

  return bareTree;
}

export function FindNearestFacilityFromEntity(obj, entityid) {
  return (
    (function walk(obj, entityid, lastFound = null) {
      if (obj?.typeid === c.ENTITY_TYPE.Facility || obj?.typeid === c.ENTITY_TYPE.ValetArea) lastFound = obj;
      if (obj?.entityid === entityid) return lastFound;
      if (obj?.children) {
        for (let item of obj?.children) {
          let found = walk(item, entityid, lastFound);
          if (found) return found;
        }
      }
    })(obj, entityid) ?? null
  );
}

export function FindNearestFacilityGroupFromEntity(obj, entityid) {
  return (
    (function walk(obj, entityid, lastFound = null) {
      if (obj?.typeid === c.ENTITY_TYPE.FacilityGroup) lastFound = obj;
      if (obj?.entityid === entityid) return lastFound;
      if (obj?.children) {
        for (let item of obj?.children) {
          let found = walk(item, entityid, lastFound);
          if (found) return found;
        }
      }
    })(obj, entityid) ?? null
  );
}

//depth first search algo
export function FindEntity(obj, targetId) {
  if (obj.entityid === targetId) {
    return obj;
  }
  if (obj.children) {
    for (let item of obj.children) {
      let check = FindEntity(item, targetId);
      if (check) {
        return check;
      }
    }
  }
  return null;
}

export function GetAllDevicesIds(entityList) {
  if (entityList == null || entityList.length == 0) {
    return [];
  }
  let devicesIds = [];
  (function walk(entityList) {
    if (!entityList) return;
    if (entityList.type?.toLowerCase() === "device") {
      devicesIds.push(entityList.entityid);
    }
    if (entityList.children) {
      for (let item of entityList.children) {
        walk(item);
      }
    }
  })(entityList);
  return devicesIds;
}

export function DetermineIssuesOnGivenEntity(entity) {
  return buildErrors(entity);
}

export function DetermineIssuesOnEntity(obj, entityid) {
  const entity = FindEntity(obj, entityid);
  return buildErrors(entity);
}

export const DetermineErrorClassesByPeripheralType = (
  _entity,
  _peripheralType
) => {
  let _classList = [];
  let _listByPeripheral =
    DetermineIssuesOnGivenEntity(_entity).filter(
      (e) => e.Type === _peripheralType
    ) ?? [];
  _listByPeripheral.map((_error) => {
    if (_error.Severity === c.SEVERITY.LOW) _classList.push("low");
    if (_error.Severity === c.SEVERITY.WARNING) _classList.push("warning");
    if (_error.Severity === c.SEVERITY.CRITICAL) _classList.push("critical");
    if (_error.Severity === c.SEVERITY.WORLD_IS_ON_FIRE)
      _classList.push("fire");
  });
  _classList.push("norm"); //just to test we can get multiples (sim printer / printer dsd not quite right)
  return _.uniq(_classList);
};

const buildErrors = (entity) => {
  let errorArray = [];
  if (entity.state == null) {
    errorArray.push({
      Type: c.ERROR_TYPES.Device,
      Severity: c.SEVERITY.CRITICAL,
      Message: "DEVICE NOT RESPONDING",
    });
    return errorArray; //if device isn't responding, dont bother testing everything else
  }


  if (entity?.state?.Peripherals?.CreditCardReader?.IsConfigured ?? false) {
    if (entity.state?.Peripherals?.CreditCardReader?.Tampered)
      errorArray.push({
        Type: c.ERROR_TYPES.CCReader,
        Severity: c.SEVERITY.WORLD_IS_ON_FIRE,
        Message: "CC Reader Detected Tampering",
      });
    if (entity.state?.Peripherals?.CreditCardReader?.NoCommunications)
      errorArray.push({
        Type: c.ERROR_TYPES.CCReader,
        Severity: c.SEVERITY.CRITICAL,
        Message: "CC Reader Not Found",
      });
    if (entity.state?.Peripherals?.CreditCardReader?.HostOffline)
      errorArray.push({
        Type: c.ERROR_TYPES.CCReader,
        Severity: c.SEVERITY.CRITICAL,
        Message: "CC Reader Host Offline",
      });
    if (entity.state?.Peripherals?.CreditCardReader?.ReaderOffline)
      errorArray.push({
        Type: c.ERROR_TYPES.CCReader,
        Severity: c.SEVERITY.CRITICAL,
        Message: "CC Reader Offline",
      });
  }

  if (entity?.state?.Peripherals?.BNR?.IsConfigured ?? false) {
    if (entity.state?.Peripherals?.BNR?.CashBoxFull)
      errorArray.push({
        Type: c.ERROR_TYPES.BNR,
        Severity: c.SEVERITY.WARNING,
        Message: "BNR Cash Box Full",
      });
    if (entity.state?.Peripherals?.BNR?.CashMachineStatus === 1)
      errorArray.push({
        Type: c.ERROR_TYPES.BNR,
        Severity: c.SEVERITY.WORLD_IS_ON_FIRE,
        Message: "BNR Requires Maintenance",
      });
    if (entity.state?.Peripherals?.BNR?.CashMachineStatus === 2)
      errorArray.push({
        Type: c.ERROR_TYPES.BNR,
        Severity: c.SEVERITY.WARNING,
        Message: "BNR Offline",
      });
  }

  //loops
  if (entity?.state?.Peripherals?.Gate?.IsConfigured ?? false) {
    if (entity.state?.Peripherals?.Gate?.ArmingLoop === c.LOOP_STATUS.Unset) {
      errorArray.push({
        Type: c.ERROR_TYPES.ArmingLoop,
        Severity: c.SEVERITY.LOW,
        Message: "Arming Loop Unknown",
      });
    }
    if (entity.state?.Peripherals?.Gate?.ClosingLoop === c.LOOP_STATUS.Unset) {
      errorArray.push({
        Type: c.ERROR_TYPES.ClosingLoop,
        Severity: c.SEVERITY.LOW,
        Message: "Closing Loop Unknown",
      });
    }
  }

  if (entity.state?.Peripherals?.Printer?.IsConfigured ?? false) {
    if (entity?.deviceMode === c.DEVICE_MODE.ENTRY && !entity?.rateid) {
      errorArray.push({
        Type: c.ERROR_TYPES.Rate,
        Severity: c.SEVERITY.WARNING,
        Message: "NO RATE ASSIGNED",
      });
    }

    if (entity.state?.Peripherals?.Printer?.Low) {
      errorArray.push({
        Type: c.ERROR_TYPES.Printer,
        Severity: c.SEVERITY.LOW,
        Message: "Printer Paper Low",
      });
    }
    if (entity.state?.Peripherals?.Printer?.Jam) {
      errorArray.push({
        Type: c.ERROR_TYPES.Printer,
        Severity: c.SEVERITY.CRITICAL,
        Message: "Printer Jammed",
      });
    }
    if (entity.state?.Peripherals?.Printer?.Out) {
      errorArray.push({
        Type: c.ERROR_TYPES.Printer,
        Severity: c.SEVERITY.CRITICAL,
        Message: "Printer Out of Paper",
      });
    }
    if (!entity.state?.Peripherals?.Printer?.Online) {
      errorArray.push({
        Type: c.ERROR_TYPES.Printer,
        Severity: c.SEVERITY.CRITICAL,
        Message: "Printer Offline",
      });
    }
  }
  return errorArray;
};
