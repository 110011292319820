class ValidationAccountService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async getRateSwitchRates(facilityID) {
    return await this.apiClient.get(`validations/${facilityID}/rates`);
  }

  async getValidationOfferByInventoryIdAttendedCashier(
    validationID,
    facilityID
  ) {
    return await this.apiClient.get(
      `attendedcashiervalidateofferbyid/${validationID}/${facilityID}`
    );
  }

  async getValidationOfferByInventoryIdRoamingCashier(
    validationID,
    facilityID
  ) {
    return await this.apiClient.get(
      `roamingcashiervalidateofferbyid/${validationID}/${facilityID}`
    );
  }

  async getValidationAccount(facilityID, contractHolderID) {
    return await this.apiClient.get(
      `validationacct/entity/${facilityID}/${contractHolderID}`
    );
  }

  async getValidationAccounts(facilityID, limit, offset, searchTerm) {
    return await this.apiClient.get(`validationacct/entity/${facilityID}`, {
      params: { limit, offset, searchTerm },
    });
  }

  async getValidationAccountHolderByUserID(userID, facilityID) {
    return await this.apiClient.get(
      `validationacct/user/${userID}/${facilityID}`
    );
  }

  async getValidationAccountsForUser(facilityID, userID, whereEnabled = null) {
    return await this.apiClient.get(
      `validationacct/user/${userID}/${facilityID}`,
      { params: { whereEnabled } }
    );
  }

  async deleteValidationAccount(contractHolderID) {
    return await this.apiClient.delete(`validationacct/${contractHolderID}`);
  }

  async createValidationAccount(validationAccount) {
    return await this.apiClient.post("validationacct", validationAccount, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async updateValidationAccount(validationAccount) {
    return await this.apiClient.put(`validationacct`, validationAccount, {
      headers: { "Content-Type": "application/json" },
    });
  }

  async getMerchantValidationOffers(contractHolderID, facilityID) {
    return await this.apiClient.get(
      `validations/offers/${contractHolderID}/${facilityID}`
    );
  }

  async getValidationOfferHistory(validationofferID) {
    return await this.apiClient.get(
      `validations/offershistory/${validationofferID}`,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async associateValidationAccountUsers(validationAccountID, userIDs) {
    return await this.apiClient.put(
      `validationacct/${validationAccountID}/users`,
      userIDs,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async updateValidationAccountUsers(
    validationAccountID,
    addedIds,
    deletedIds
  ) {
    return await this.apiClient.put(
      `validationacct/updateusers`,
      {
        AddedUserIds: addedIds,
        DeletedUserIds: deletedIds,
        ValidationAccountID: validationAccountID,
      },
      { headers: { "Content-Type": "application/json" } }
    );
  }

  async getValidationOffersForGivenValidationAccounts(
    entityID,
    validationAccountIDs,
    ticketFacilityID
  ) {
    return await this.apiClient.post(
      `validations/offers/grouped`,
      {
        entityID,
        contractHolderIDs: validationAccountIDs, 
        ticketFacilityID,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  /**
   * OBSOLETE
   */
  async getValidationOffersForGivenValidationAccountsAttendedCashier(
    entityID,
    validationAccountIDs
  ) {
    return await this.apiClient.post(
      'attendedcashier/validations/offers/grouped',
      {
        entityID,
        contractHolderIDs: validationAccountIDs, 
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  /**
   * OBSOLETE
   */
  async getValidationOffersForGivenValidationAccountsRoamingCashier(
    entityID,
    validationAccountIDs
  ) {
    return await this.apiClient.post(
      'roamingcashier/validations/offers/grouped',
      {
        entityID,
        contractHolderIDs: validationAccountIDs, 
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async getValidationOffersForCashier(entityID, validationAccountIDs, ticketFacilityID) {
    return await this.apiClient.post(
      'cashier/validations/offers/grouped',
      {
        entityID,
        contractHolderIDs: validationAccountIDs, 
        ticketFacilityID,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  async deleteValidationAccountAndUsers(contractHolderID) {
    return await this.apiClient.delete(`validationaccount/${contractHolderID}`);
  }
}

export default ValidationAccountService;
