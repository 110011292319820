import React, { useMemo, useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  FormControlLabel,
  Typography,
  Grid,
  Switch,
  MenuItem,
  TextField,
  Button,
  Tooltip,
  Box
} from "@material-ui/core";
import * as Yup from "yup";
import useFormHandlers from "../useFormHandlers";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlags";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import SettingsResetButton from "../SettingsResetButton";
import { useFlags } from "launchdarkly-react-client-sdk";
import NestDeviceForm from "./NestDeviceForm";

const TerminalInterfaceForm = ({
  entityID,
  entityType,
  settingValues,
  onRatesClicked,
  onPeripheralsClicked,
  onOccupancyClicked,
  onGPIOClicked,
  onCCGatewayClicked,
  onCardFormatsClicked,
  onReversingLanesClicked,
  settingValuesParent,
  onLogosAndBannersClicked
}) => {

  const TerminalInterfaceValidation = useMemo(() => (Yup.object().shape({
    displaylotfull: Yup.string(),
    backouttimeout: Yup.number()
      .typeError("Must be a number")
      .integer("Must be a whole number")
      .positive("Must be greater than zero")
      .required("Required"),
    toidletimeout: Yup.number()
      .typeError("Must be a number")
      .integer("Must be a whole number")
      .positive("Must be greater than zero")
      .required("Required"),
    centralpaytrxtimeout: Yup.number()
      .typeError("Must be a number")
      .integer("Must be a whole number")
      .positive("Must be greater than 10000 milliseconds")
      .moreThan(10000, "Must be greater than 10000 milliseconds")
      .lessThan(120000, "Must be less than 120000 milliseconds")
      .required("Required"),
    duplicatecredentialreaddelay: Yup.number()
      .typeError("Must be a number")
      .integer("Must be a whole number")
      .positive("Must be greater than 1000 milliseconds")
      .moreThan(1000, "Must be greater than 1000 milliseconds")
      .lessThan(15000, "Must be less than 15000 milliseconds")
      .required("Required"),
    laneclosedsign: Yup.string().required(),
    devicedisabled: Yup.string().required(),
    // printerretracttimeout: Yup.number()
    //   .typeError("Must be a number")
    //   .integer("Must be a whole number")
    //   .positive("Must be greater than zero")
    //   .required("Required"),
    autoprintreceipt: Yup.string().required(),
    ticket_footer: Yup.string(),
    ticket_header: Yup.string(),
    receipt_footer: Yup.string(),
    receipt_header: Yup.string(),
    issueticketwhilefull: Yup.string(),
    // lostticketbuttonisenabled: Yup.string(),
    ccreplyto: Yup.string(),
    lostticketprice: Yup.number()
      .typeError("Must be a number")
      .positive("Must be greater than zero"),
    emailfrom: Yup.string(),
    emailreply: Yup.string(),
    emailtemplate: Yup.string(),
    ccdeviceid: Yup.string().max(16, "Cannot exceed 16 characters"),
    valetdeviceid: Yup.string()
      .test('noSpaces', 'Value cannot contain spaces', (value) => {
        if (value) {
          if (value.includes(' ')) {
            return false
          }
        }
        return true
      })
      .matches(/^[0-9]+$/, 'Value must be a number')
      .max(8, 'Value cannot exceed 8 digits')

  })), []);

  const theme = useTheme();
  const history = useHistory();
  const { hasPermissions } = useHasPermissions();
  const {
    taxes: taxFeatureFlag,
    deviceHighContrast: deviceHighContrastFlag,
    contractNest: contractNestLDFlag
  } = useFlags();
  const [deviceMode, setDeviceMode] = useState(settingValues?.devicemode?.toLowerCase()); 

  const RatesViewPermission = hasPermissions(["rates.view"], false, entityID);
	const isCVPSEnabled = useFeatureFlag("CVPS Dual Pairing");
  const contractNest = useFeatureFlag("Contract Nest");
  const isLogosAndBannersEnabled = useFeatureFlag("Logos and Banners");

  const {
    values,
    setFieldValue,
    initialValues,
    errors,
    handleChange
  } = useFormik({
    initialValues: settingValues,
    validationSchema: TerminalInterfaceValidation
  });

  useEffect(() => {
    settingValues?.devicemode && setDeviceMode(settingValues.devicemode.toLowerCase());
  }, [settingValues?.devicemode]);

  const {
    handleToggle,
    handleInputChange,
    handleSelectChange,
    canRenderFieldByMode,
    handleResetChange,
    canRenderField,
  } = useFormHandlers(setFieldValue, initialValues, entityID, entityType);

  const canRenderLogosAndBanners = () => {
    return isLogosAndBannersEnabled && canRenderFieldByMode("logosandbanners", deviceMode) 
  }

  const logosAndBannersButton = () => {
    return (
      <Button
        fullWidth
        variant="contained"
        onClick={onLogosAndBannersClicked}
        color="primary"
      >
        Logos and Banners
      </Button>
    )
  }

  return (
    <>
      {canRenderFieldByMode("devicedisabled", deviceMode) && (
        <Grid
          data-tag="devicedisabled"
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <FormControlLabel
            labelPlacement="end"
            control={
              <Switch
                name="devicedisabled"
                checked={values.devicedisabled}
                data-checked={values.devicedisabled}
                onChange={e => handleToggle(values, errors, e)}
              />
            }
            label={
              <span
                style={{
                  color:
                    values.devicedisabled === true
                      ? theme.palette.error.main
                      : theme.palette.text.primary
                }}
              >
                {deviceMode !== "door" ? "Lane Closed" : "Disabled"}
              </span>
            }
          />
        </Grid>
      )}
      {entityType?.toLowerCase() === "device" && !deviceHighContrastFlag && (
        <Grid item data-tag="spacer" xs={12} sm={2} />
      )}
      {deviceHighContrastFlag && canRenderFieldByMode("devicehighcontrast", deviceMode) && (
        <Tooltip
        placement="top-end"
        title="This option changes the color scheme on the device screen to be easier to read when in direct sunlight."
        >
          <Grid
            data-tag="devicehighcontrast"
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
          >
            <FormControlLabel
              labelPlacement="end"
              data-testid="device-high-contrast-switch"
              control={
                <Switch
                  name="devicehighcontrast"
                />
              }
              label={
                <span
                  style={{
                    color:
                      values.devicecontrast === true
                        ? theme.palette.error.main
                        : theme.palette.text.primary
                  }}
                >
                  High Contrast Mode
                </span>
              }
            />
          </Grid>
        </Tooltip>
      )}

      {contractNest
        && contractNestLDFlag
        && canRenderField("devicenesting")
        && (
          <NestDeviceForm
            entityID={entityID}
            deviceMode={deviceMode}
          />
        )}

      {canRenderFieldByMode("rates", deviceMode) && (
        <Grid item xs={12} sm={6} md={6} lg={deviceMode !== "door" ? 4 : 6}>
          {RatesViewPermission && (
            <Button
              className={clsx(["btn-rates"])}
              fullWidth
              variant="contained"
              onClick={onRatesClicked}
              color="primary"
            >
              Rates
            </Button>
          )}
        </Grid>
      )}
      {canRenderFieldByMode("laneclosed", deviceMode) && 1 === 2 && (
        <Grid
          data-tag="laneclosedsign"
          item
          xs={12}
          sm={6}
          md={7}
          lg={7}
          xl={7}
        >
          <FormControlLabel
            labelPlacement="end"
            control={
              <Switch
                name="laneclosedsign"
                checked={values.laneclosedsign}
                data-checked={values.laneclosedsign}
                onChange={e => handleToggle(values, errors, e)}
              />
            }
            label="Lane Closed"
          />
        </Grid>
      )}
      {canRenderFieldByMode("peripherals", deviceMode) && (
        <Grid item xs={12} sm={6} md={6} lg={deviceMode !== "door" ? 4 : 6}>
          <Button
            className={clsx(["peripherals"])}
            variant="contained"
            fullWidth
            onClick={onPeripheralsClicked}
            color="primary"
          >
            Peripherals
          </Button>
        </Grid>
      )}
      {canRenderFieldByMode("gpio", deviceMode) && (
        <Grid item xs={12} sm={6} md={6} lg={deviceMode !== "door" ? 4 : 6}>
          <Button
            className={clsx(["gpio"])}
            variant="contained"
            fullWidth
            name="GPIO"
            onClick={onGPIOClicked}
            color="primary"
          >
            GPIO
          </Button>
        </Grid>
      )}
      {entityType.toLowerCase() === "device" && canRenderLogosAndBanners() && (
        <Grid item xs={12} sm={6} md={6} lg={deviceMode !== "door" ? 4 : 6}>
          {logosAndBannersButton()}
        </Grid>
      )}
      <Box sx={{width:"100%"}}/>
      {canRenderFieldByMode("displaylotfull", deviceMode) && (
        <Grid
          data-tag="displaylotfull"
          item
          xs={12}
          sm={entityType?.toLowerCase() === "facility" ? 4 : 6}
          md={entityType?.toLowerCase() === "facility" ? 4 : 6}
          lg={entityType?.toLowerCase() === "facility" ? 4 : 6}
          xl={entityType?.toLowerCase() === "facility" ? 4 : 6}
        >
          <TextField
            data-id="displaylotfull"
            id="displaylotfull"
            name="displaylotfull"
            label={<Typography>Lot Full</Typography>}
            select
            variant="outlined"
            SelectProps={{
              SelectDisplayProps: { "data-testid": "displaylotfull" }
            }}
            style={
              entityType?.toLowerCase() != "facility"
                ? {
                    width:
                      values.displaylotfull !=
                      settingValuesParent.displaylotfull
                        ? "calc(100% - 25px)"
                        : "100%"
                  }
                : { width: "100%" }
            }
            onChange={(e, selected) =>
              handleSelectChange(errors, e, selected)
            }
            defaultValue={values["displaylotfull"] ?? ""}
            value={values["displaylotfull"] ?? ""}
            error={errors && errors["displaylotfull"] ? true : false}
            helperText={
              (errors && errors["displaylotfull"]) ||
              "when to enable lot full"
            }
          >
            <MenuItem value="auto">
              Auto{" "}
              <Typography
                variant="overline"
                style={{
                  color: theme.palette.text.disabled,
                  position: "absolute",
                  top: 0,
                  right: 5
                }}
              >
                By Occupancy
              </Typography>
            </MenuItem>
            <MenuItem value="off">
              Off{" "}
              <Typography
                variant="overline"
                style={{
                  color: theme.palette.text.disabled,
                  position: "absolute",
                  top: 0,
                  right: 5
                }}
              >
                Manual
              </Typography>
            </MenuItem>
            <MenuItem value="on">
              On{" "}
              <Typography
                variant="overline"
                style={{
                  color: theme.palette.text.disabled,
                  position: "absolute",
                  top: 0,
                  right: 5
                }}
              >
                Manual
              </Typography>
            </MenuItem>
          </TextField>
          <SettingsResetButton
            style={{ marginTop: -5 }}
            entityID={entityID}
            entityType={entityType}
            settingName="displaylotfull"
            currentValue={values.displaylotfull}
            originalValue={settingValuesParent.displaylotfull}
            onClick={() => {
              handleResetChange(
                "displaylotfull",
                settingValuesParent.displaylotfull
              );
            }}
          />
        </Grid>
      )}
      {canRenderFieldByMode("allowtransactionswhilegateraised", deviceMode) && (
          <>
            <Grid
              data-tag="allowtransactionswhilegateraised"
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <FormControlLabel
                labelPlacement="end"
                data-id="allowtransactionswhilegateraised"
                data-testid="allowtransactionswhilegateraised"
                control={
                  <Switch
                    name="allowtransactionswhilegateraised"
                    checked={values.allowtransactionswhilegateraised}
                    data-checked={values.allowtransactionswhilegateraised}
                    onChange={e => handleToggle(values, errors, e)}
                    color="primary"
                  />
                }
                label="Allow Transactions While Gate Raised"
              />
              <SettingsResetButton
                style={{ marginTop: -5 }}
                entityID={entityID}
                entityType={entityType}
                settingName="allowtransactionswhilegateraised"
                currentValue={values.allowtransactionswhilegateraised}
                originalValue={settingValuesParent.allowtransactionswhilegateraised}
                onClick={() => {
                  handleResetChange(
                    "allowtransactionswhilegateraised",
                    settingValuesParent.allowtransactionswhilegateraised
                  );
                }}
              />
            </Grid>
          </>
        )}
      {entityType?.toLowerCase() === "facility" &&
        canRenderField("lostticketallowvalidations") ?
        (
          <>
            <Grid
              data-tag="lostticketallowvalidations"
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <FormControlLabel
                labelPlacement="end"
                data-id="lostticketallowvalidations"
                data-testid="lostticketallowvalidations"
                control={
                  <Switch
                    name="lostticketallowvalidations"
                    checked={values.lostticketallowvalidations}
                    data-checked={values.lostticketallowvalidations}
                    onChange={e => handleToggle(values, errors, e)}
                    color="primary"
                  />
                }
                label="Allow Validation On Lost Ticket"
              />
            </Grid>
          </>
        ) : (
          entityType?.toLowerCase() === "facility" && (
          <Grid
            data-tag="spacer-for-facility"
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          />)
        )}
      {canRenderFieldByMode("backouttimeout", deviceMode) && (
        <Grid
          data-tag="backouttimeout"
          item
          xs={12}
          sm={entityType?.toLowerCase() === "facility" ? 4 : 6}
          md={entityType?.toLowerCase() === "facility" ? 4 : 6}
          lg={entityType?.toLowerCase() === "facility" ? 4 : 6}
          xl={entityType?.toLowerCase() === "facility" ? 4 : 6}
        >
          <TextField
            variant="outlined"
            style={
              entityType?.toLowerCase() != "facility"
                ? {
                    width:
                      values.backouttimeout !=
                      settingValuesParent.backouttimeout
                        ? "calc(100% - 25px)"
                        : "100%"
                  }
                : { width: "100%" }
            }
            label="Backout Timeout"
            name="backouttimeout"
            placeholder="enter backout timeout"
            onChange={handleChange}
            value={values.backouttimeout}
            onBlur={e => handleInputChange(errors, e)}
            error={Boolean(errors.backouttimeout)}
            helperText={errors.backouttimeout || "in milliseconds"}
          />
          <SettingsResetButton
            entityID={entityID}
            entityType={entityType}
            settingName="backouttimeout"
            currentValue={values.backouttimeout}
            originalValue={settingValuesParent.backouttimeout}
            onClick={() => {
              handleResetChange(
                "backouttimeout",
                settingValuesParent.backouttimeout
              );
            }}
          />
        </Grid>
      )}
      {canRenderFieldByMode("toidletimeout", deviceMode) && (
        <Grid
          data-tag="toidletimeout"
          item
          xs={12}
          sm={entityType?.toLowerCase() === "facility" ? 4 : 6}
          md={entityType?.toLowerCase() === "facility" ? 4 : 6}
          lg={entityType?.toLowerCase() === "facility" ? 4 : 6}
          xl={entityType?.toLowerCase() === "facility" ? 4 : 6}
        >
          <TextField
            variant="outlined"
            style={
              entityType?.toLowerCase() != "facility"
                ? {
                    width:
                      values.toidletimeout !=
                      settingValuesParent.toidletimeout
                        ? "calc(100% - 25px)"
                        : "100%"
                  }
                : { width: "100%" }
            }
            label="Timeout To Idle"
            name="toidletimeout"
            type="text"
            onChange={handleChange}
            value={values.toidletimeout}
            onBlur={e => handleInputChange(errors, e)}
            error={Boolean(errors.toidletimeout)}
            helperText={errors.toidletimeout || "in milliseconds"}
          />
          <SettingsResetButton
            entityID={entityID}
            entityType={entityType}
            settingName="toidletimeout"
            currentValue={values.toidletimeout}
            originalValue={settingValuesParent.toidletimeout}
            onClick={() => {
              handleResetChange(
                "toidletimeout",
                settingValuesParent.toidletimeout
              );
            }}
          />
        </Grid>
      )}
      {canRenderFieldByMode("centralpaytrxtimeout", deviceMode) && (
        <Grid
          data-tag="centralpaytrxtimeout"
          item
          xs={12}
          sm={entityType?.toLowerCase() === "facility" ? 4 : 6}
          md={entityType?.toLowerCase() === "facility" ? 4 : 6}
          lg={entityType?.toLowerCase() === "facility" ? 4 : 6}
          xl={entityType?.toLowerCase() === "facility" ? 4 : 6}
        >
          {(entityType?.toLowerCase() === "facility" ||
            deviceMode === "kiosk") && (
            <Tooltip
              placement="top-end"
              title="This timeout setting covers the scenario of someone scanning a ticket then walking away. Set between 10 and 120 seconds"
            >
              <TextField
                variant="outlined"
                style={
                  entityType?.toLowerCase() != "facility"
                    ? {
                        width:
                          values.centralpaytrxtimeout !=
                          settingValuesParent.centralpaytrxtimeout
                            ? "calc(100% - 25px)"
                            : "100%"
                      }
                    : { width: "100%" }
                }
                label="Central Pay Transaction Timeout"
                name="centralpaytrxtimeout"
                type="text"
                onChange={handleChange}
                value={values.centralpaytrxtimeout}
                onBlur={e => handleInputChange(errors, e)}
                error={Boolean(errors.centralpaytrxtimeout)}
                helperText={
                  errors.centralpaytrxtimeout || "in milliseconds"
                }
              />
            </Tooltip>
          )}
          {deviceMode  === "kiosk" && (
            <SettingsResetButton
              entityID={entityID}
              entityType={entityType}
              settingName="centralpaytrxtimeout"
              currentValue={values.centralpaytrxtimeout}
              originalValue={settingValuesParent.centralpaytrxtimeout}
              onClick={() => {
                handleResetChange(
                  "centralpaytrxtimeout",
                  settingValuesParent.centralpaytrxtimeout
                );
              }}
            />
          )}
        </Grid>
      )}
      {canRenderFieldByMode("duplicatecredentialreaddelay", deviceMode) && (
        <Grid
          data-tag="credential"
          item
          xs={12}
          sm={entityType?.toLowerCase() === "facility" ? 4 : 6}
          md={entityType?.toLowerCase() === "facility" ? 4 : 6}
          lg={entityType?.toLowerCase() === "facility" ? 4 : 6}
          xl={entityType?.toLowerCase() === "facility" ? 4 : 6}
        >
          <Tooltip
            placement="top-end"
            title="Number of milliseconds to ignore duplicate credential reads"
          >
            <TextField
              variant="outlined"
              style={
                entityType?.toLowerCase() != "facility"
                  ? {
                      width:
                        values.duplicatecredentialreaddelay !=
                        settingValuesParent.duplicatecredentialreaddelay
                          ? "calc(100% - 25px)"
                          : "100%"
                    }
                  : { width: "100%" }
              }
              label="Duplicate Credential Read Delay"
              name="duplicatecredentialreaddelay"
              type="text"
              onChange={handleChange}
              value={values.duplicatecredentialreaddelay}
              onBlur={e => handleInputChange(errors, e)}
              error={Boolean(errors.duplicatecredentialreaddelay)}
              helperText={
                errors.duplicatecredentialreaddelay || "in milliseconds"
              }
            />
          </Tooltip>
          <SettingsResetButton
            entityID={entityID}
            entityType={entityType}
            settingName="duplicatecredentialreaddelay"
            currentValue={values.duplicatecredentialreaddelay}
            originalValue={
              settingValuesParent.duplicatecredentialreaddelay
            }
            onClick={() => {
              handleResetChange(
                "duplicatecredentialreaddelay",
                settingValuesParent.duplicatecredentialreaddelay
              );
            }}
          />
        </Grid>
      )}
      {entityType?.toLowerCase() === "facility" && (
        <Grid
          data-tag="spacer-for-facility"
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
        ></Grid>
      )}
      {canRenderFieldByMode("occupancy", deviceMode) && (
        <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
          <Button
            className={clsx(["btn-occupancy"])}
            fullWidth
            variant="contained"
            onClick={onOccupancyClicked}
            color="primary"
          >
            Occupancy Availability
          </Button>
        </Grid>
      )}
      {canRenderFieldByMode("manageusers", deviceMode) && (
        <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
          <Button
            className={clsx(["btn-manageusers"])}
            fullWidth
            variant="contained"
            onClick={() => history.push("/users")}
            color="primary"
          >
            Manage Users
          </Button>
        </Grid>
      )}
      {canRenderFieldByMode("creditcardgateway", deviceMode) && (
        <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
          <Button
            className={clsx(["btn-ccgateway"])}
            fullWidth
            variant="contained"
            onClick={onCCGatewayClicked}
            color="primary"
          >
            Credit Card Gateway
          </Button>
        </Grid>
      )}
      {canRenderFieldByMode("cardformats", deviceMode) && (
        <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
          <Button
            className={clsx(["btn-cardformats"])}
            fullWidth
            variant="contained"
            onClick={onCardFormatsClicked}
            color="primary"
          >
            Card Formats
          </Button>
        </Grid>
      )}
      {canRenderFieldByMode("taxSettings", deviceMode) && taxFeatureFlag && (
        <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
        <Button
          className={clsx(["btn-taxes"])}
          fullWidth
          variant="contained"
          data-testid="tax-settings-btn"
          onClick={ () => history.push("/rates", { typeName : "taxes"})}
          color="primary"
        >
          Taxes
        </Button>
      </Grid>
      )}
      {canRenderFieldByMode("reversingLanes", deviceMode) && (
        <Grid item xs={12} sm={4} md={6} lg={4}>
          <Button
            className={clsx(["btn-reversingLanes"])}
            fullWidth
            variant="contained"
            data-testid="reversing-lanes-btn"
            onClick={onReversingLanesClicked}
            color="primary"
          >
            Reversing Lanes
          </Button>
      </Grid>
      )}
      {entityType.toLowerCase() === "facility" && canRenderLogosAndBanners() && (
        <Grid item xs={12} sm={4} md={6} lg={4}>
          {logosAndBannersButton()}
        </Grid>
      )}
			{isCVPSEnabled && canRenderFieldByMode("valetdeviceid", deviceMode) && (
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant="outlined"
            label="CVPS Valet Device ID"
            name="valetdeviceid"
            type="text"
            onChange={handleChange}
            value={values.valetdeviceid}
            onBlur={e => handleInputChange(errors, e)}
            error={Boolean(errors.valetdeviceid)}
            helperText={errors.valetdeviceid}
            inputProps={{
              'data-testid': 'valetdeviceid-input'
            }}
            FormHelperTextProps={{
              'data-testid': `valetdeviceid-helper-text`
            }}
            fullWidth={window.screen.width <= 600 ? true : false}
          />
        </Grid>
      )}
    </>
  );
};

TerminalInterfaceForm.defaultProps = {
  onTaxesClicked: () => {},
  onRatesClicked: () => {},
  onOccupancyClicked: () => {},
  onCCGatewayClicked: () => {},
  onCardFormatsClicked: () => {},
  onLogosAndBannersClicked: () => {}
};

export default TerminalInterfaceForm;