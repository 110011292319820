import { useCallback, useRef, useEffect, useState } from "react";
import axios from "axios";

/**
 * A hook that is used to cancel outbound api requests, in order to eliminate duplicate requests
 * @param {Function} func
 * The API request that the useCancellationToken hook will execute when calling the execute function
 * @param {Function} errHandleFunc
 * The callback that is executed when the API request throws a non-cancellation exception
 */
const useCancellationToken = ({ func, errHandleFunc }) => {
  const cancellationTokenSource = useRef(axios.CancelToken.source());
  const [inProgress, setInProgress] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      cancel();
    };
  }, []);

  const execute = useCallback(
    async (props) => {
      if (cancellationTokenSource.current) {
        cancellationTokenSource.current.cancel();
      }

      cancellationTokenSource.current = axios.CancelToken.source();

      try {
        setInProgress(true);
        var result = await func({
          ...props,
          cancelToken: cancellationTokenSource.current.token,
        });
        if (isMounted.current) {
          setInProgress(false);
        }
        return result;
      } catch (ex) {
        if (!axios.isCancel(ex)) {
          console.error("Cancellable request failed", ex);
          if (isMounted.current) {
            setInProgress(false);
          }
          if (errHandleFunc) {
            errHandleFunc(ex);
          }
        }
      }
    },
    [func, errHandleFunc]
  );

  const cancel = () => {
    if (cancellationTokenSource.current) {
      setInProgress(false);
      cancellationTokenSource.current.cancel();
    }
  };

  return {
    execute,
    cancel,
    inProgress: inProgress,
  };
};

export default useCancellationToken;
